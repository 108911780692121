import React, {useState} from "react";
import Carousel from "react-elastic-carousel";
import * as Style from "./responsive-carousel.module.less"

const ResponsiveCarousel = ({
	breakPointMap,
	shouldAutoPlay,
	children,
	doesNotUseDefaultBreakPoints,
	shouldFocusOnSelect
 }) => {
	const defaultBreakPointMap = {
		1: 0,
		2: 350,
		3: 495,
		4: 700,
		5: 950
	}
	const baseProperties = {
		autoPlaySpeed: 3000,
		enableAutoPlay: shouldAutoPlay,
		focusOnSelect: shouldFocusOnSelect,
		itemsToShow: 3,
		pagination: false
	}
	if (!doesNotUseDefaultBreakPoints) {
		baseProperties.breakPoints = Object.entries(defaultBreakPointMap)
			.map(([itemsToShow, width]) => ({
				itemsToShow: Number(itemsToShow),
				width: breakPointMap?.[Number(itemsToShow)] ?? width
			}))
	}
	else if (breakPointMap) {
		baseProperties.breakPoints = Object.entries(breakPointMap)
			.map(([itemsToShow, width]) => ({
				itemsToShow: Number(itemsToShow),
				width
			}))
	}
	const carouselRef = React.useRef(null)
	const timeout = baseProperties.autoPlaySpeed + 500
	const [timeoutId, setTimeoutId] = useState()
	return (
		<Carousel
			isRTL={ false }
			className={ Style.carousel }
			ref={ carouselRef }
			onNextEnd={() => {
				clearTimeout( timeoutId )
				const timeoutIdTemp = setTimeout(() => {
					carouselRef?.current?.goTo(0)
				}, timeout)
				setTimeoutId(timeoutIdTemp)
			}}
			{ ...baseProperties }
		>
			{ children }
		</Carousel>
	)
}
export default ResponsiveCarousel