import React from "react";
import Layout from "../components/layout";
import {Helmet} from "react-helmet";
import {useTranslation} from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Favicon from "../media/favicon/x.ico";

const NotFoundPage = ({ data }) => {
    const {
        t
    } = useTranslation()
    return (
            <Layout>
                <Helmet>
                    <title>404 | Team Parallax</title>
                    <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
                </Helmet>
                <h1>404</h1>
                <p>{t("404.content")}</p>
            </Layout>
    );
};
export default NotFoundPage;
export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
