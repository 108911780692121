import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import Render from "../components/render";
import Technology from "../components/technology-pane/index";
import ProjectOverviewHorizontal from "../components/projectOverviewHorizontal";
import ProductOverview from "../components/product-overview/index"
import CustomersOverview from "../components/customer-overview/customersOverview"
import HeaderSlider from "../components/header-slider/index";
const LandingPage =  ({ data }) => {
  const {
    intro,
    products,
    projects,
    customers,
    technology
  } = data
  return (
    <Layout>
      <HeaderSlider data={products.edges}/>
      {/* This part adds the product overviews to the landing page. */}
      <ProductOverview data={products.edges}/>
      <CustomersOverview data={customers.edges} />
      <div
        style={{
            marginTop: "3rem"
        }}
      >
          <div>{Render(intro.htmlAst)}</div>
      </div>
      {/* This part adds the project overviews to the landing page. */}
      <ProjectOverviewHorizontal data={projects.edges} />
      <Technology data={technology} />
      {/*<ProfileOverview data={data.profiles} />*/}
    </Layout>
  );
};
export default LandingPage
export const query = graphql`
  query ($language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    intro: markdownRemark(
      frontmatter: {
        title: { eq: "intro" }
      }
      fields: {
        language: { eq: $language }
      }
    ) {
      htmlAst
      frontmatter {
        title
      }
    }
    profiles: allMarkdownRemark (
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        frontmatter: {  category: { eq: "profile" }}
        fields: {
          language: { eq: $language }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            name
            dev_type
            expertise
            degree
            technology
            imagePath {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { 
          language: { eq: $language }
        }
        frontmatter: {
          isDraft: { ne: true }
          category: { eq: "projects" }
        }
      }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            preview {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    products: allMarkdownRemark(
        filter: {
            fields: {
              language: { eq: $language }
            }
            frontmatter: {
              category: { eq: "portfolio" }
              isDraft: { ne: true }
            }
        }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            servicesList
            servicesText
            smallImagePath {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 300) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
              slug
          }
          excerpt
        }
      }
    }
    technology: allMarkdownRemark(
        filter: {
          fields: {
            language: { eq: $language }
          }
          frontmatter: {
            category: { eq: "technologyStack" }
          }
        } 
    ){
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            title
            category
            imagePath {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
          }
        }
      }
    }
    customers: allMarkdownRemark (
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        frontmatter: {
          category: { eq: "customers" }
          isDraft: { ne: true }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            name
            link
            imagePath {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
