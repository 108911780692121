// extracted by mini-css-extract-plugin
export var gatsbyRespImageLink = "profile-module--gatsby-resp-image-link--LaN8t";
export var box = "profile-module--box--E2hT0";
export var flexbox = "profile-module--flexbox--FiCzE";
export var input = "profile-module--input--y4tzv";
export var loader = "profile-module--loader--QsnB-";
export var load8 = "profile-module--load8--ph2yr";
export var gatsbyRespImageBackgroundImage = "profile-module--gatsby-resp-image-background-image--+HWsU";
export var profile = "profile-module--profile--GmqHe";
export var expertise = "profile-module--expertise--6cJDB";
export var expertiseList = "profile-module--expertiseList--EFtHH";
export var profileHeader = "profile-module--profileHeader--Kej9P";
export var profileHeaderContent = "profile-module--profileHeaderContent--76bb1";
export var image = "profile-module--image--WA3AP";
export var socialImage = "profile-module--socialImage--fZvF0";
export var cert = "profile-module--cert--yEtR8";
export var title = "profile-module--title--CWrUp";
export var description = "profile-module--description--zP64i";
export var socials = "profile-module--socials--COYZc";
export var socialsList = "profile-module--socialsList--WAQOF";
export var certList = "profile-module--certList--IVIRv";