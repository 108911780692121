import React from 'react';
import {Helmet} from 'react-helmet';
import {graphql} from 'gatsby';
import Layout from '../../components/layout';
import Img from 'gatsby-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as s from './success-story.module.less';
import ConclusionSvg from "../../media/svg/conclusion.svg"
import NewSitutationSvg from "../../media/svg/new-situation.svg"
import OldSituationSvg from "../../media/svg/old-situation.svg"
import SolutionSvg from "../../media/svg/solution.svg"
import techStack from '../../data/technology';
import {splitStringBeforeTag} from "../../utils/index"
import Badge from '../../components/badge';
import Button from '../../components/button';
const ProfileTemplate = ({data}) => {
    const sectionIcons = [
        <OldSituationSvg/>,
        <SolutionSvg/>,
        <NewSitutationSvg/>,
        <ConclusionSvg/>
    ]
    const { t } = useTranslation()
    const successStory = data.successStory
    const frontmatter = successStory.frontmatter;
    const pdf = data.pdf
    const sections = splitStringBeforeTag(successStory.html, "h2")
	const imagePath = frontmatter.imageDarkPath ?? frontmatter.imagePath
    const imageBackground = frontmatter.imageBackdrop !== false ? frontmatter.imageBackdrop : "transparent"
    return (
        <Layout>
            <Helmet
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <meta name="description" content={`Success story of ${frontmatter.companyName}`}/>
                <title>Success Story: {frontmatter.companyName}</title>
            </Helmet>
            <div className={s.wrapper}>
                <div className={ s.title }>
                    <div
                        className={ s.logoWrapper}
                        style={{ backgroundColor: imageBackground }}
                    >
                        {!imagePath.childImageSharp && imagePath.extension === 'svg'
                            ? (
                                <img
                                    style={{objectFit: "contain"}}
                                     src={imagePath.publicURL}
                                     alt={frontmatter.name} />
                            )
                            : (
                                <Img
                                    className={ s.logo }
                                    alt={frontmatter.name}
                                    durationFadeIn={0}
                                    fadeIn={false}
                                    loading={"eager"}
                                    fluid={imagePath?.childImageSharp?.fluid}
                                />
                            )}
                    </div>
                    <h2  className={ s.text }>
                        {t("workWith")}<div dangerouslySetInnerHTML={{__html: frontmatter.companyName}}/>
                    </h2>
                </div>
                <div className={s.technologies}>
                    {
                        frontmatter.technology.map((technology) => {
                            return (<Badge label={techStack[technology].name}/>)
                        })
                    }
                </div>
                { frontmatter.introduction && (
                    <div className={ s.introduction }>
                        <h2> {t("successStory.introduction")}</h2>
                        { frontmatter.introduction }
                    </div>
                ) }
                <div className={s.sections}>
                    {sections.map((section, index) => {
                        return (
                            <div
                                className={s.section}
                                key={`success-story-${frontmatter.companyName}_section-${index}`}
                            >
                                <div className={s.svg}>
                                    {sectionIcons[index]}
                                </div>
                                <div dangerouslySetInnerHTML={{__html: section}}/>
                            </div>)
                    })}
                </div>
                <Button
                    label={t("successStory.download")}
                    href={pdf?.publicURL}
                    isDownload
                    buttonType={ "secondary" }
                    size={"small"}
                    className={s.button}
                />
            </div>
        </Layout>
    );
};
export default ProfileTemplate
export const query = graphql`
  query($slug: String!, $language: String!, $name: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
            node {
                ns
                data
                language
            }
        }
    }
    successStory: markdownRemark(
		frontmatter: {
			successStory: { eq: true }
			publish: { eq: true }
		}
      fields: {
        slug: { eq: $slug } 
        language: { eq: $language } 
      }
    ) {
      html
      frontmatter {
        technology
        companyName
        imagePath {
            childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
            }
            extension
			publicURL
        }
        imageDarkPath {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
            extension
            publicURL
        }
        imageBackdrop,
        introduction
      }
      fields {
        slug
      }
    }
    pdf: file(name: {eq: $name}, ext: {eq: ".pdf"}){
        name
        publicURL
    }
  }
`;
