import React from "react"
import RightArrow from "../../../../media/landing-page/right-arrow.png"
import * as s from "./service.module.less"
const Service = ({service}) => {
  return (
    <div className={ s.service }>
      <img className={ s.serviceImg } src={ service.img } alt={ service.title }/>
      <div className={ s.textBox }>
        <div className={ s.titleBox }>
          <h2 className={ s.title }>{service.title}</h2>
        </div>
        <p className={ s.description }>{service.description}</p>
      </div>
      <div className={ s.btnBox }>
        <button className={ s.btn } onClick={ service.btn.onClick }>
          <a href={ service.btn.href }>
            { service.btn.text.toUpperCase() }
          </a>
        </button>
        <img
          alt="Right-Arrow"
          className={ s.rightArrow }
          src={ RightArrow }
        />
      </div>
    </div>
  )
}
export default Service