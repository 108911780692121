import React, {useState} from "react"
import * as Style from "./product-overview.module.less"
import classNames from "classnames";
import * as PStyle from "../projectOverviewHorizontal.module.less";
import {useTranslation} from "gatsby-plugin-react-i18next";
import ProductBox from "./product-box/index"
import Pane from "../pane/pane";
import PaneTitle from "../pane/pane-title/pane-title";
const ProductOverview = ({ data }) => {
	const [isExtended, setIsExtended] = useState(false)
	const {
		t
	} = useTranslation()
	return (
		<Pane>
			<div className={ classNames(PStyle.flexbox, Style.viewWidth) }>
				<PaneTitle
					title={t("portfolio.title")}
					description={t("portfolio.description")}
					isCentered={ true }
				/>
				<div className={ classNames(Style.products, {
					[Style.extended]: isExtended
				}) }>
					{ data.map(({ node }) => (
						<ProductBox
							key={node.frontmatter.title}
							node={ node }
						/>
					)) }
				</div>
				<div
					className={ classNames(Style.buttonHolder, {
						[Style.extended]: isExtended
					}) }
					onClick={ ()=> {
						setIsExtended(!isExtended)
					}}
				/>
			</div>
		</Pane>
	)
}
export default ProductOverview
