import React from 'react'
import * as Style from './technology.module.less'
import Img from "gatsby-image";
const TechnologyPane = ({ data }) => {
	const {
		edges: technology
	} = data
	const technologyData = technology[0]?.node
	const frontmatter = technologyData?.frontmatter
	const html = technologyData?.html
	if (!frontmatter) {
		console.warn("No Technology Pane Data found");
		return null
	}
	return (
		<div className={ Style.technologyContainer }>
			<div className={ Style.textContainer }>
				<h2>{ frontmatter.title }</h2>
				<div dangerouslySetInnerHTML={ { __html: html } }/>
			</div>
			<div className={ Style.imageContainer }>
				<Img
					className={ Style.smallImage }
					alt={ frontmatter.title }
					durationFadeIn={ 0 }
					fadeIn={ false }
					loading={ "eager" }
					fluid={ frontmatter.imagePath.childImageSharp.fluid }
				/>
			</div>
		</div>
	)
}
export default TechnologyPane