import React from "react";
import * as Style from "./page-navigation.module.less";
import { Link } from 'gatsby-plugin-react-i18next';
import cn from "classnames"

export const PageNavigation = ({
	pageContext,
	totalPostCount
}) => {
	const { currentPage, postsPerPage } = pageContext
	const numPages = Math.ceil(totalPostCount / postsPerPage)
	const currentPageIsFirstPage = currentPage === 1
	const currentPageIsLastPage = currentPage === numPages
	const nextPageSlug = `/blog/${ (currentPage + 1) }`
	const prevPageSlug = currentPage - 1 === 1
		? `/blog`
		: `/blog/${ (currentPage - 1) }`
	let pageIndicatorList = [];
	const pageIndicatorNeighbors = 3;
	for(let numPage = 1; numPage <= numPages; numPage++) {
		if(numPage > currentPage - pageIndicatorNeighbors && numPage < currentPage + pageIndicatorNeighbors) {
			pageIndicatorList.push(numPage)
		}
	}
	return (
		<div className={ Style.pageNav }>
			<div className={ cn(Style.prev, Style.noUnderline, {
				[Style.invisible]: currentPageIsFirstPage
			}) }>
				<Link to={ prevPageSlug } rel="prev">
					<i className={ cn(Style.arrow, Style.left) }/>
				</Link>
			</div>
			<div className={ Style.pageNum }>
				{ !pageIndicatorList.includes(1) &&
					<>
						<div className={ cn(Style.num, Style.noUnderline, {
						}) }>
							<Link to={`/blog`}>
								{ 1 }
							</Link>
						</div>
						<>..</>
					</>
				}
				{
					pageIndicatorList.map(pageNumber => (
						<div className={ cn(Style.num, Style.noUnderline, {
							[Style.currentPage]: pageNumber === currentPage,
						}) }>
							<Link to={
								pageNumber === 1
									? `/blog`
									: `/blog/${ pageNumber }`
							}>
								{ pageNumber }
							</Link>
						</div>
					))
				}
				{ !pageIndicatorList.includes(numPages) &&
				<>
					<>..</>
					<div className={ cn(Style.num, Style.noUnderline, {
					}) }>
						<Link to={`/blog/${numPages}`}>
							{ numPages }
						</Link>
					</div>
				</>
				}
			</div>
			<div className={ cn(Style.next, Style.noUnderline, {
				[Style.invisible]: currentPageIsLastPage
			}) }>
				<Link to={ nextPageSlug } rel="next">
					<i className={ cn(Style.arrow, Style.right) }/>
				</Link>
			</div>
		</div>
	)
}