import React from "react"
import Img from 'gatsby-image'
import RightArrow from "../../../../media/landing-page/right-arrow.png"
import * as s from "./testimonial-box.module.less"
import LinesEllipsis from 'react-lines-ellipsis'
const TestimonialBox = ({
	testimonial: {
		name,
		company,
		description,
		img,
		href
	}
}) => {
	const MAX_LINES= 3
	return (
		<div
			className={ s.testimonialBox }
		>
			<div className={ s.imgBox }>
				<Img
					className={ s.img }
					alt={name}
					fadeIn={true}
					imgStyle={{objectFit: "cover"}}
					loading={"lazy"}
					fluid={img}
				/>
			</div>
			<div className={ s.textBox }>
				<p className={ s.heading }>
					{ name } - {company}
				</p>
				<span className={s.description}>
					{description}
				</span>
				{/*<LinesEllipsis*/}
				{/*	className={ s.description}*/}
				{/*	text={ description }*/}
				{/*	maxLine={MAX_LINES}*/}
				{/*	ellipsis="..."*/}
				{/*	trimRight*/}
				{/*	basedOn="letters"*/}
				{/*/>*/}
			</div>
			{href &&
				<div className={ s.btnBox }>
					<a href={href}>
						READ MORE
					</a>
					<img
					alt="Right-Arrow"
					className={ s.rightArrow }
					src={ RightArrow }
					/>

				</div>
			}
		</div>
	)
}
export default TestimonialBox
