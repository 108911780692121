import React from "react";
import { Link } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import Img from "gatsby-image";
import * as Style from "./profileOverview.module.less"
import * as PStyle from "./projectOverviewHorizontal.module.less"
function renderProjectNode(node) {
	return (
		<div key={ node.id }>
			<Link to={ node.fields.slug }>
				<div className={ Style.profile }>
					{node.frontmatter.preview ? (
						<Img
							className={ Style.image }
							durationFadeIn={0}
							loading={"eager"}
							fadeIn={false}
							alt={ node.frontmatter.title }
							fluid={node.frontmatter.preview.childImageSharp.fluid}
						/>
					) : (
						""
					)}
					<div className={ Style.description }>
						<h3>
							{ node.frontmatter.title }
						</h3>
					</div>
				</div>
			</Link>
		</div>
	);
}
const ProjectOverviewHorizontal = ({ data }) => {
	const previewProjects = data.slice(0,3)
	/* @TODO if mre projects are here, then we can show it again :) */
	return null
	return (
		<div>
			<div className={ classNames(PStyle.flexbox, Style.viewwidth) }>
				<h2 style={{
					textAlign: "center"
				}}> Projects </h2>
				<div className={ Style.profiles }>
					{ previewProjects.map(({ node }) => (
						renderProjectNode(node)
					)) }
				</div>
				<div
					className={ PStyle.buttonHolder }
				>
					<Link
						to={`/projects`}
					>
						See all Projects
					</Link>
				</div>
			</div>
		</div>
	);
};
export default ProjectOverviewHorizontal