import Particles from "react-tsparticles";
import * as Style from "./particle-wrapper.module.less";
import React from "react";
const ParticleWrapper = ({
	customParticlesOption,
	backgroundColor,
	particleColor,
	linkColor,
	height,
	id
}) => {
	const defaultParticlesOption = {
		background: {
			color: {
				value: `${backgroundColor}`,
				size: "contain"
			},
		},
		fpsLimit: 30,
		interactivity: {
			events: {
				onClick: {
					enable: false,
					mode: "push",
				},
				onHover: {
					enable: false,
					mode: "repulse",
				},
				resize: true,
			},
			modes: {
				bubble: {
					distance: 400,
					duration: 2,
					opacity: 0.8,
					size: 40,
				},
				push: {
					quantity: 4,
				},
				repulse: {
					distance: 200,
					duration: 0.4,
				},
			},
		},
		particles: {
			color: {
				value: `${particleColor}`,
			},
			links: {
				color: `${linkColor}`,
				distance: 150,
				enable: true,
				opacity: 0.5,
				width: 2,
			},
			collisions: {
				enable: true,
			},
			move: {
				direction: "none",
				enable: true,
				outMode: "bounce",
				random: false,
				speed: 0.5,
				straight: false,
			},
			number: {
				density: {
					enable: true,
					value_area: 800,
				},
				value: 100,
			},
			opacity: {
				value: 0.5,
			},
			shape: {
				type: "circle",
			},
			size: {
				random: true,
				value: 3,
			},
		},
		detectRetina: true,
	}
	const particlesOption = customParticlesOption ?? defaultParticlesOption
	return (
		<Particles
			className={ Style.particles }
			options={ particlesOption }
			height={ height }
			id={ id }
		/>
	)
}
export default ParticleWrapper