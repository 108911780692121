import * as Style from "./product-box.module.less";
import {Link} from "gatsby-plugin-react-i18next";
import Shadow from "../../../media/svg/shadow.svg";
import Plate from "../../../media/svg/plate.svg";
import Img from "gatsby-image";
import React from "react";
const ProductBox = ({node}) => {
	return (
		<div
			key={ node.id }
			className={ Style.product }
		>
			<Link
				className={ Style.link }
				to={ node.fields.slug }
			>
				<div className={ Style.productContainer }>
					<Shadow
						className={ Style.shadow }
					/>
					<div className={ Style.imageContainer }>
						<Plate
							className={ Style.plate }
						/>
						{ node.frontmatter.smallImagePath ? (
							<Img
								className={ Style.image }
								durationFadeIn={ 0 }
								loading={ "eager" }
								fadeIn={ false }
								alt={ node.frontmatter.title }
								fluid={ node.frontmatter.smallImagePath.childImageSharp.fluid }
							/>
						) : (
							""
						)}
					</div>
					<div className={ Style.description }>
						<h3>
							{ node.frontmatter.title }
						</h3>
						<i>
							{ node.frontmatter.subtitle }
						</i>
					</div>
				</div>
			</Link>
		</div>
	)
}
export default ProductBox