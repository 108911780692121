import React from "react";
import Layout from "../../components/layout";
import {Helmet} from "react-helmet";
import {useTranslation} from "gatsby-plugin-react-i18next";
import * as Style from "./datenschutz.module.less";
import { graphql } from "gatsby";
import Favicon from "../../media/favicon/x.ico";

const DatenschutzPage = ({ data }) => {
    const {
        t
    } = useTranslation()
    return (
            <Layout>
                <Helmet>
                    <title>Datenschutz | Team Parallax</title>
                    <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
                </Helmet>
                <div className={Style.list} dangerouslySetInnerHTML={{ __html: data.datenschutz.edges[0].node.html }} />
            </Layout>
    );
};
export default DatenschutzPage;
export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        datenschutz: allMarkdownRemark(filter: {frontmatter: {category: {eq: "datenschutz"}}}) {
            edges {
                node {
                    html
                }
            }
        }
    }
`;
