import React from "react"
import { Widget, PopupButton } from '@typeform/embed-react'
import * as containerStyles from "./typeform.module.less"
import cn from "classnames"
export const Typeform = ({
    typeformId,
    popUpText,
    className
}) => {
    if(popUpText) {
        return (
            <PopupButton
                id={typeformId}
                className={ className }
            >
                {popUpText}
            </PopupButton>
        )
    } else {
        return (
            <Widget
                id={typeformId}
                className={ cn(containerStyles.widget, className) }                
            />
        )
    }
}
