import React from "react";
import * as PostCommentStyle from "./post-comment.module.less";
import axios from "axios";

export default class PostComment extends React.Component {
  constructor({ props, post, parentId }) {
    super(props);
    this.slug = post.node?.fields.slug ?? post.fields.slug;
    this.post = post;
    this.state = {
      comment: "",
      name: "",
      email: "",
      error: "",
      parentId: parentId || "",
      readOnly: false
    };

    this.submit = this.submit.bind(this);
  }
  testSubmission() {
    this.setState({ readOnly: true });
    setTimeout(() => {
      this.props.onSubmitPost({
        id: Date.now(),
        date: Date.now(),
        comment: this.state.comment,
        name: this.state.name,
        email: this.state.email.toLowerCase(),
        parentId: this.state.parentId,
        slug: this.slug
      });
      this.setState({ readOnly: false });
    }, 500);
  }

  async submit(e) {
    e.preventDefault();

    // this.testSubmission();
    // return false;

    if (this.state.readOnly) return false;

    this.setState({ readOnly: true });

    const data = {
      fields: {
        comment: this.state.comment,
        name: this.state.name,
        email: this.state.email.toLowerCase(),
        parentId: this.state.parentId,
        slug: this.slug
      },
      options: {
        slug: this.slug
      }
    };

    try {
      const res = await axios.post(
        `https://comments.team-parallax.com/v3/entry/gitlab/insopde/team-parallax/master/comments`,
        data
      );

      const comment = res.data.fields;
      this.props.onSubmitPost(comment);

      this.setState({
        readOnly: false,
        comment: "",
        name: "",
        email: "",
        error: ""
      });
    } catch (e) {
      // console.log(e);
      this.setState({
        readOnly: false,
        error: "An Error has occured - please try again later"
      });
    }
  }

  changeHandler(e, attribute) {
    this.setState({ [attribute]: e.target.value });
  }

  render() {
    return (
      <div>
        <form onSubmit={this.submit}>
          <textarea
            className={PostCommentStyle.comment}
            name="fields[comment]"
            placeholder="Enter your Comment"
            required
            value={this.state.comment}
            readOnly={this.state.readOnly}
            onChange={e => {
              this.changeHandler(e, "comment");
            }}
          />
          <div className={PostCommentStyle.flex}>
            <input
              className={PostCommentStyle.name}
              name="fields[name]"
              type="text"
              placeholder="Name"
              required
              value={this.state.name}
              readOnly={this.state.readOnly}
              onChange={e => {
                this.changeHandler(e, "name");
              }}
            />
            <input
              className={PostCommentStyle.email}
              name="fields[email]"
              type="email"
              placeholder="Email"
              required
              value={this.state.email}
              readOnly={this.state.readOnly}
              onChange={e => {
                this.changeHandler(e, "email");
              }}
            />
            <button className={PostCommentStyle.submit} type="submit">
              {this.state.readOnly ? (
                <div className="loader" />
              ) : (
                "Post Comment"
              )}
            </button>
          </div>
          {this.state.error ? (
            <div className={PostCommentStyle.error}>{this.state.error}</div>
          ) : (
            ""
          )}
        </form>
      </div>
    );
  }
}
