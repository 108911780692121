// extracted by mini-css-extract-plugin
export var gatsbyRespImageLink = "product-module--gatsby-resp-image-link--MsjJR";
export var box = "product-module--box--pbGdF";
export var flexbox = "product-module--flexbox--fe6hy";
export var input = "product-module--input--1TLeu";
export var loader = "product-module--loader--+1KwC";
export var load8 = "product-module--load8--mWDI4";
export var gatsbyRespImageBackgroundImage = "product-module--gatsby-resp-image-background-image--BZVCg";
export var headerContainer = "product-module--headerContainer--nEtie";
export var orange = "product-module--orange--hIono";
export var purpleToOrange = "product-module--purple-to-orange--gIA1n";
export var purple = "product-module--purple--o0LYR";
export var petrol = "product-module--petrol--9-Y7Y";
export var petrolToOrange = "product-module--petrol-to-orange--5m0MK";
export var headerContent = "product-module--headerContent--IfQwW";
export var headerImage = "product-module--headerImage--4tfVj";
export var buzzwordContainer = "product-module--buzzwordContainer--Jwwrb";
export var description = "product-module--description--xOOpb";
export var content = "product-module--content--JINVJ";
export var servicesContainer = "product-module--servicesContainer--7Hfw4";
export var servicesHolder = "product-module--servicesHolder--UkmTv";
export var list = "product-module--list--ThoNf";
export var imageHolder = "product-module--imageHolder--Bf7ca";
export var smallImage = "product-module--smallImage--wn4WG";