// extracted by mini-css-extract-plugin
export var gatsbyRespImageLink = "profileOverview-module--gatsby-resp-image-link--8JzwL";
export var box = "profileOverview-module--box--1ybQu";
export var flexbox = "profileOverview-module--flexbox--MGdxw";
export var input = "profileOverview-module--input--8PurE";
export var loader = "profileOverview-module--loader--P4KWN";
export var load8 = "profileOverview-module--load8--sAVsP";
export var gatsbyRespImageBackgroundImage = "profileOverview-module--gatsby-resp-image-background-image--IiN1l";
export var profile = "profileOverview-module--profile--UxVVq";
export var image = "profileOverview-module--image--7Hbku";
export var description = "profileOverview-module--description--IycGp";
export var viewwidth = "profileOverview-module--viewwidth--8xefA";
export var profiles = "profileOverview-module--profiles--2jsXk";
export var employeesLink = "profileOverview-module--employeesLink--nm1+5";