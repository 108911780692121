import React from "react";
import PostComment from "./post-comment";
import Comments from "./comments";

export default class CommentSection extends React.Component {
  constructor({ props, post, comments }) {
    comments = comments || [];

    super(props);
    this.post = post;
    this.state = { comments };

    this.addPostToComments = this.addPostToComments.bind(this);
  }

  addPostToComments(comment) {
    this.setState({ comments: [...this.state.comments, { comment }] });
  }

  render() {
    return (
      <div>
        <h3>Add a comment</h3>
        <PostComment onSubmitPost={this.addPostToComments} post={this.post} />
        <Comments comments={this.state.comments} post={this.post} />
      </div>
    );
  }
}
