import React from "react";
import * as s from "./pane.module.less"
const Pane = ({
	children,
	bgColor
}) => {
	return (
		<div
			className={ s.paneWrapper }
			style={{
				backgroundColor: `${bgColor}`
			}}
		>
			{ children }
		</div>
	)
}
export default Pane
