// extracted by mini-css-extract-plugin
export var wrapper = "success-story-module--wrapper--5q+NW";
export var title = "success-story-module--title--jT7J+";
export var logoWrapper = "success-story-module--logoWrapper--+ruWf";
export var text = "success-story-module--text--pTWPw";
export var logo = "success-story-module--logo--ZJmx4";
export var technologies = "success-story-module--technologies--LEhW-";
export var introduction = "success-story-module--introduction--Dqknw";
export var sections = "success-story-module--sections--W5WVf";
export var section = "success-story-module--section--5bMBt";
export var svg = "success-story-module--svg--S4flJ";
export var button = "success-story-module--button--2cKDL";