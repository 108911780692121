// extracted by mini-css-extract-plugin
export var gatsbyRespImageLink = "technology-module--gatsby-resp-image-link--bJA0G";
export var box = "technology-module--box--EYy-z";
export var flexbox = "technology-module--flexbox--9GiK4";
export var input = "technology-module--input--U9cxA";
export var loader = "technology-module--loader--U8OaV";
export var load8 = "technology-module--load8---p4Gy";
export var gatsbyRespImageBackgroundImage = "technology-module--gatsby-resp-image-background-image--alJtr";
export var stack = "technology-module--stack--78fWJ";
export var elem = "technology-module--elem--fbBPH";
export var img = "technology-module--img--RESof";
export var techLabel = "technology-module--techLabel--069iL";