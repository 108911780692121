import React, { useState } from "react";
import * as s from "./header.module.less";
import { graphql, StaticQuery } from "gatsby";
import cn from "classnames"
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import MenuIcon from "../../../media/svg/menu1.svg"
import logo from "../../../media/logo/tpx_s_orange.png"
import * as landingPageStyle from "../landing-page.module.less";
const Header = ({
	navElements
}) => {
	const { t } = useTranslation()
	const [shouldShowNavBar, setShouldShowNavBar] = useState(false)
	const getNavElements = () => {
		return (
			<div className={s.navElements}>
				<div className={s.navLinks}>
					{
						navElements.map((element) => (
							<Link
								to={element.href}
								activeClassName={s.activeLink}
								className={s.navLink}
								onClick={() => setShouldShowNavBar(false)}
							>
								{element.text}
							</Link>

						))
					}
				</div>
				<Link to={`/contact`} className={s.connectbutton}>
					{t("button.contactButton")}
				</Link>
			</div>
		)
	}
	return (
		<StaticQuery
			query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    ` }
			render={data => (
				<div className={ s.headerBlocker }>
					<div className={s.headerWrapper}>
						<div className={cn(s.header, landingPageStyle.container)}>
								<Link to={`/`} className={ s.logo }>
									<img
										alt={"Logo of TeamParallax"}
										src={logo}
									/>
								</Link>
								<nav className={s.navHorizontal}>
									{getNavElements()}
								</nav>
								<div
									className={s.burgerMenuIcon}
									onClick={() => setShouldShowNavBar(true)}
								>
									<MenuIcon />
								</div>
								<div className={cn(s.navSideBar, {
										[s.show]: shouldShowNavBar
									})}
								>
								<div
									className={s.overlay}
									onClick={() => setShouldShowNavBar(false)}
								/>
								<nav className={s.navSideBarBox}>
									{getNavElements()}
								</nav>
							</div>
						</div>
					</div>
				</div>
			)}
		/>
	);
}
export default Header;
