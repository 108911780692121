// extracted by mini-css-extract-plugin
export var pageNav = "page-navigation-module--pageNav--BmQ7+";
export var prev = "page-navigation-module--prev--e8dkQ";
export var pageNum = "page-navigation-module--pageNum--ZpyfP";
export var num = "page-navigation-module--num--ueFvH";
export var currentPage = "page-navigation-module--currentPage--KcLcK";
export var noUnderline = "page-navigation-module--noUnderline--whCMa";
export var next = "page-navigation-module--next--H9WhC";
export var arrow = "page-navigation-module--arrow--uMh8D";
export var right = "page-navigation-module--right--mw+Aq";
export var left = "page-navigation-module--left--0UHw6";
export var invisible = "page-navigation-module--invisible--hg2Qq";