import React from "react";
import { graphql } from "gatsby";
import { Link } from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout";
import Img from "gatsby-image";
import * as Style from "./portfolio.module.less";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import Favicon from "../media/favicon/x.ico";
const PortfolioPage =  ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Portfolio | Team Parallax</title>
        <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
      </Helmet>
      <div
        className={ Style.portfolio }
      >
        <h4>Portfolio</h4>
        {data.products.edges.map(({ node }) => (
          <div key={node.id}>
            <Link className={Style.post} to={node.fields.slug}>
              <div className={Style.description}>
                {node.frontmatter.smallImagePath ? (
                  <Img
                    key={ node.frontmatter.smallImagePath }
                    className={ classNames(Style.preview, Style[node.frontmatter.background])}
                    fluid={node.frontmatter.smallImagePath.childImageSharp.fluid}
                  />
                ) : (
                  ""
                )}
                <div
                  className={ Style.titleHolder }
                >
                    <h3>{node.frontmatter.title}</h3>
                    <i>{node.frontmatter.subtitle}</i>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  );
};
export default PortfolioPage
export const query = graphql`
  query($language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
              node {
                  ns
                  data
                  language
              }
          }
      }
    products: allMarkdownRemark(
        sort: { fields: [frontmatter___title], order: ASC }
        filter: {
          fields: { language: { eq: $language } }
          frontmatter: {
            isDraft: { ne: true }
            category: { eq: "portfolio" }
          }
        }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            background
            smallImagePath {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
