import React, {useEffect, useRef, useState} from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image'
import {Link, useI18next} from "gatsby-plugin-react-i18next"
import * as s from "./success-story-list.module.less"
import techStack from "../../data/technology"
import Badge from "../../components/badge"
import Layout from "../../components/layout"
import { Helmet } from "react-helmet"
import Select from 'react-select'
import Favicon from "../../media/favicon/x.ico";
const SuccessStories = ({data}) => {
    const {t} = useI18next()
	const successStories = data.successStories.edges.sort((a,b) => a.node.frontmatter.companyName.localeCompare(b.node.frontmatter.companyName))
	const successStoryTechnologies = [...new Set(successStories.map(story => {
		return story.node.frontmatter.technology
	}).flat())]
	const options = Object.entries(techStack).map(([key, value]) => {
		return {value: value.id, label: value.name}
	})
	const availbleOptions = options.filter(option => successStoryTechnologies.includes(option.value))
	const [filteredSuccessStories, setFilteredSuccessStories] = useState(null)
	const [selectedOptions, setSelectedOptions] = useState(availbleOptions)
	const handleChange = (option) => {
		setSelectedOptions(option)
	}
	const initialRender = useRef(true)
	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false
		  } else {
			const selectedOptionValues = new Set(selectedOptions.map(option => option.value))
			const filteredSuccessStories = successStories.filter(story => {
				return (
					Array.from(selectedOptionValues).every(val => story.node.frontmatter.technology.includes(val))
				)
			}
			)
			setFilteredSuccessStories(filteredSuccessStories)
		}
	}, [selectedOptions])
	const stories = filteredSuccessStories ?? successStories
	return (
		<Layout>
			<Helmet>
				<title>Success Stories | Team Parallax</title>
				<link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
			</Helmet>
			<div className={s.listWrapper}>
				<div>
					<h2>{t("successStory.title")}</h2>
					<div className={s.subtitle}>{t("successStory.subtitle")}</div>
				</div>
				<Select
					options={availbleOptions}
					isMulti
					onChange={handleChange}
					theme
					className={s.select}
					placeholder={t("successStory.filter")}
				/>
			{
				stories.map(successStory => {
					const frontmatter = successStory.node.frontmatter
					const imagePath = frontmatter.imageDarkPath ?? frontmatter.imagePath
					const imageBackground = frontmatter.imageBackdrop !== false ? frontmatter.imageBackdrop : "transparent"
					return (

						<Link className={s.listElement} to={`/success-story${successStory.node.fields.slug}`}>
							<div
								className={s.left}
								style={{ backgroundColor: imageBackground }}
							>
								{!imagePath.childImageSharp && imagePath.extension === 'svg'
								? (
										<img style={{objectFit: "contain"}} src={imagePath.publicURL} alt={frontmatter.name} />
									)
								: (
										<Img
											className={ s.logo }
											alt={frontmatter.name}
											durationFadeIn={0}
											fadeIn={false}
											imgStyle={{objectFit: "contain"}}
											loading={"eager"}
											fluid={imagePath?.childImageSharp?.fluid}
										/>
									)}

							</div>

							<div className={s.right}>
								<h3>
									<div dangerouslySetInnerHTML={{__html: frontmatter.companyName}}/>
								</h3>
								{frontmatter.technology && (
									<div className={ s.technology }>
										{
											frontmatter.technology.map(technology => {
												return (
													<Badge label={techStack[technology]?.name ?? `>${technology}<`}/>
												)
											})
										}
									</div>
								)}
							</div>

						</Link>
				)
			})}

		</div>
		</Layout>
	)
}
export default SuccessStories
export const query = graphql`
query($language: String!) {
	locales: allLocale(filter: {language: {eq: $language}}) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	successStories: allMarkdownRemark(
		filter: {
			frontmatter: {
				successStory: { eq: true }
				publish: { eq: true }
			}
			fields: {
				language: { eq: $language }
			}
		}
		
	  ) {
		edges {
			node {
				frontmatter {
					language
					technology
					companyName
					imagePath {
						childImageSharp {
							fluid {
							  ...GatsbyImageSharpFluid
							}
						}
						extension
						publicURL
					}
					imageDarkPath {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
						}
						extension
						publicURL
					}
					imageBackdrop,
				}
				fields {
					slug
				}
			}
		}
		
	  }
}
`
