import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import * as Style from './product.module.less';
import CustomersOverview from "../components/customer-overview/customersOverview";
import ProjectOverviewHorizontal from "../components/projectOverviewHorizontal";
import ReactMarkdown from "react-markdown";
import NotFound from "../pages/404";
import classNames from "classnames";
const ProductTemplate = ({ data }) => {
	const product = data.product;
	if(!product) {
		return <NotFound/>
	}
	const frontmatter = product.frontmatter;
	const buzzwords = frontmatter.buzzwords.slice(0,4);
	return (
		<Layout
			language={data.locales.edges[0].node.language}
		>
			<Helmet
				htmlAttributes={{
					/* @TODO i18n */
					lang: "en",
				}}
			>
				<meta name="description" content={`Product: ${frontmatter.title}`}/>
				<title> { product.frontmatter.title }</title>
			</Helmet>
			<div
				className={ classNames(Style.headerContainer, Style[frontmatter.background]) }
			>
				<div
					className={ Style.headerContent }
				>
					<Img
						className={ Style.headerImage }
						alt={ frontmatter.title }
						durationFadeIn={0}
						fadeIn={false}
						loading={"eager"}
						fluid={ frontmatter.headerImagePath.childImageSharp.fluid }
					/>
					<div
						className={ Style.buzzwordContainer }
					>
						<p>
							{ buzzwords[0] }
						</p>
						<p>
							{ buzzwords[1] }
						</p>
						<p>
							{ buzzwords[2] }
						</p>
						<p>
							{ buzzwords[3] }
						</p>
					</div>
				</div>
			</div>
			<div
				className={ Style.description }
			>
				<h1> { frontmatter.title }</h1>
			</div>
			<div
				className={ Style.content }
				dangerouslySetInnerHTML={ { __html: product.html } }
			/>
			<CustomersOverview data={data.customers.edges} overwriteWidth />
			<div
				className={ Style.servicesContainer }
				hidden={ !frontmatter.smallImagePath.childImageSharp.fluid }
			>
				<div
					className={ Style.servicesHolder}
				>
					<h2>{ frontmatter.servicesTitle }</h2>
					{
						frontmatter.servicesText &&
							<ReactMarkdown>{frontmatter.servicesText}</ReactMarkdown>
					}
					{
						frontmatter.servicesList &&
							<ul>
								{ frontmatter.servicesList.map(service => (
									<li
									className={ Style.list }
									key={ service }
									>
										<ReactMarkdown>
											{ service }
										</ReactMarkdown>
									</li>
								))}
							</ul>
					}
				</div>
				<div
					className={ Style.imageHolder}
				>
					<Img
						className={ Style.smallImage }
						alt={ frontmatter.title }
						durationFadeIn={0}
						fadeIn={false}
						loading={"eager"}
						fluid={ frontmatter.smallImagePath.childImageSharp.fluid }
					/>
				</div>
			</div>
			<ProjectOverviewHorizontal data={data.projects.edges} />
		</Layout>
	);
};
export default ProductTemplate
export const query = graphql`
  query($slug: String!, $language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
              node {
                  ns
                  data
                  language
              }
          }
      }
    product: markdownRemark(
        fields: {
			slug: { eq: $slug }
            language: { eq: $language }
		}) {
      html
      frontmatter {
        title
        subtitle
        subtitle
        background
        buzzwords
        servicesTitle
        servicesText
        servicesList
        headerImagePath {
            childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
            }
        }
        smallImagePath {
            childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
      }
      fields {
        slug
      }
    }
    projects: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
            fields: {
                language: { eq: $language }
            }
            frontmatter: {
				isDraft: { ne: true }
				category: { eq: "projects" }
			}
        }
        limit: 3
    ) {
        totalCount
        edges {
            node {
                id
                frontmatter {
                    title
                    subtitle
                    preview {
                        childImageSharp {
                            fluid(maxWidth: 300) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    date(formatString: "DD MMMM, YYYY")
                }
                fields {
                    slug
                }
                excerpt
            }
        }
    }
    customers: allMarkdownRemark (
        sort: { fields: [frontmatter___order], order: ASC }
        filter: {
            frontmatter: {
				category: { eq: "customers" }
				isDraft: { ne: true }
			}
        }
    ) {
        totalCount
        edges {
            node {
                id
                frontmatter {
                    name
                    link
                    imagePath {
                        childImageSharp {
                            fluid(maxWidth: 200) {
                                ...GatsbyImageSharpFluid
                            }
                          }
                    }
                }
                fields {
                    slug
                }
            }
        }
    }
  }
`;
