import React from "react"
import { graphql } from "gatsby";
import AbstractLandingPageDe from "../../components/landing-page/de"
import landingImg from "../../media/landing-page/landing.jpg"
import {extractContentBetweenTags, splitStringBeforeTag} from "../../utils";
import {useI18next} from "gatsby-plugin-react-i18next";
const DevelopmentLanding = ({data}) => {
	const {
		testimonials
	} = data
	const {
		language
	} = useI18next()
	console.log("language", language);
	const landingContent = {
		heading: "Für alle IT-Sicherheitsunternehmen, die",
		btn: {
			text: "Besprechung Vereinbaren",
			href: "#simplymeet",
		},
		description: <h2>
			<span>ihre Website vor Angriffen und Datenverstößen schützen wollen, ohne:</span>
			<ul>
				<li>vulnerablem Backend oder Serverseitigem Code</li>
				{/*<li>Sicherheitsschwachstellen zu übersehen</li>*/}
				<li>rechtliche Anforderungen zu missachten</li>
				<li>die Leistung der Website zu beeinträchtigen</li>
			</ul>
		</h2>
	}
    const product = data.products.edges
    const services = product.map(item => {
      return {
        img: item.node.frontmatter.smallImagePath.childImageSharp.fluid.src,
        title: item.node.frontmatter.title,
        description: item.node.frontmatter.summary,
        btn: {
            text: "read more",
            href: `/de${item.node.fields.slug}`
        }
      }
    })
	const testimonialPage = "development"
	const testimonialData = testimonials.edges.filter(({node}) => node.fields.language === language && node.frontmatter.pages.includes(testimonialPage)).map((item) => {
		return {
			img: item.node.frontmatter.imagePath.childImageSharp.fluid,
			name: item.node.frontmatter.name,
			company: item.node.frontmatter.company,
			description: extractContentBetweenTags(splitStringBeforeTag(item.node.html, "h2").slice(-1)[0], "p"),
			href: item.node.frontmatter.href
		}
	})
	return (
		<AbstractLandingPageDe
			services={ services }
			landingImg={ landingImg }
			landingContent={ landingContent }
			testimonials={ testimonialData }
			page={"development"}
		/>
	)
}
export default DevelopmentLanding
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
            node {
                ns
                data
                language
            }
        }
    }
    products: allMarkdownRemark(
        limit: 3
        sort: { fields: [frontmatter___title], order: ASC }
        filter: {
            fields: {
                language: { eq: $language }
            }
            frontmatter: {
              category: { eq: "portfolio" }
              isDraft: { ne: true }
              isDevelopment: { eq: true }
            }
        }
    ) {
      edges {
        node {
          id
          frontmatter {
            summary
            title
            smallImagePath {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
      testimonials: allMarkdownRemark(
          filter: {
				fields: {
					language: { eq: $language }
				}
              frontmatter: {
                  category: {eq: "testimonial"}
                  publish: {eq: true }
              }
          }
      ) {
          edges {
              node {
                  html
                  fields {
                      language
                      slug
                  }
                  frontmatter {
                      name
                      company
                      href
                      pages
                      imagePath {
                          childImageSharp {
                              fluid {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
              }
          }
      }
  }
`;
