import React from "react"
import * as s from "./about.module.less";
import * as landingPageStyle from "../landing-page.module.less"
import Pane from "../../pane/pane";
import PaneTitle from "../../pane/pane-title/pane-title";
import Button from "../../button";
const About = ({
  heading,
  description1,
  description2,
  description3,
  btn,
  img
}) => {
  return (
      <Pane>
        <div className={ s.about }>
          <div className={ landingPageStyle.container }>
            <div className={ s.textBox }>
              <PaneTitle
                title={heading}
              />
              <p className={ s.description1 }>{ description1 }</p>
              <p className={ s.description2 }>{ description2 }</p>
              <p className={ s.description3 }>{ description3 }</p>
              <Button
                  label={btn.text.toUpperCase()}
                  href={btn.href}
                  size={"extraSmall"}
                  className={ s.btn }
              />
            </div>
            <img
              className={ s.whoWeAreImg }
              src={ img }
              alt="who we are"
            />
            <Button
                label={btn.text.toUpperCase()}
                href={btn.href}
                size={"extraSmall"}
                className={ s.mobileViewBtn }
            />
          </div>
        </div>
      </Pane>
  )
}
export default About
