import { Blob } from 'blob-polyfill'
export const filterNodesByLang = (edges, lang) => {
	return edges.filter(({node}) => node.frontmatter.language === lang)
}
export const getContactTypeformId = (lang) => {
	const allowedLanguages = [
		"de",
		"en"
	]
	if (!allowedLanguages.includes(lang)) {
		throw new Error(`No such CONTACTFORM variable in .env configuration for language ${lang}`);
	}
	switch (lang) {
		case "en":
			// console.log("en typeform")
			// console.log(process.env)
			return process.env.TYPEFORM_CONTACT_EN
		case "de":
		default:
			// console.log("de typeform")
			// console.log(process.env)

			return process.env.TYPEFORM_CONTACT_DE
	}
};
export const createSuccessStoryAsPdf = () => {
	// TODO: implement
}
export const createVCardBlobUrl = (t, tel, name, dev_type, mobile) => {
	const companyLandline = tel ?? "+4973114613800"
	const directTel = mobile ?? undefined
	const directTelString = directTel
		? `TEL;TYPE=CELL:${directTel}`
		: ``
	const firstName = name.split(' ').slice(0, -1).join(' ')
	const lastName = name.split(' ').slice(-1).join(' ')
	const role = dev_type
	const mail = `${name.toLowerCase().replace(" ", ".")}@team-parallax.com`
	const blob = new Blob([
`BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${firstName} ${lastName}
N;CHARSET=UTF-8:${lastName};${firstName};;
EMAIL;CHARSET=UTF-8;TYPE=WORK,INTERNET:${mail}
TEL;WORK;TYPE=VOICE:${companyLandline}
${directTelString}
ADR;CHARSET=UTF-8;TYPE=WORK:;;Magirus-Deutz-Straße 12;Ulm;;89077;${t("country.germany")}
ROLE;CHARSET=UTF-8:${role}
ORG;CHARSET=UTF-8:Team Parallax
URL;type=WORK;CHARSET=UTF-8:team-parallax.com
REV:2023-01-06T13:49:31.014Z
END:VCARD`
	], { type: "text/x-vcard" })
	return URL.createObjectURL(blob)
}

export const truncateStringInWords = (string, numberOfWords) => {
	return string.split(" ").splice(0,numberOfWords).join(" ");
}
export const splitStringBeforeTag = (inputString, tag) => inputString.split(new RegExp(`(?=<${tag})`))
export const extractContentBetweenTags = (inputString, tag) => {
	const openingTag = `<${tag}>`;
	const closingTag = `</${tag}>`;
	const regex = new RegExp(`${openingTag}(.*?)${closingTag}`);
  
	const match = inputString.match(regex);
	return match ? match[1] : "";
  }
  