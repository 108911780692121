import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby-plugin-react-i18next';
import CommentSection from "../components/comment-section";
export const BlogEntry = ({
	post,
	comments,
}) => {
	const title = post.frontmatter?.title ?? post.node?.title
	const content = post.html ?? post.node.childrenMarkdownRemark[0].html
	const date = post.frontmatter?.date ?? post?.node?.properties.publishDate?.value.start
	const authors = (post?.node?.properties?.Author.value.map(author => author.name) || [post.node?.frontmatter?.author])
		.filter((author) => author)
	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: "en",
				}}
			>
				<meta name="description" content={`Blogpost ${title}`} />
				<title>{title} | Team Parallax</title>
			</Helmet>
			<div>
				<h1>{title}</h1>
				<h2 hidden={!post.frontmatter?.subtitle}>{post.frontmatter?.subtitle}</h2>
				<p hidden={!date}>{date}</p>
				<div dangerouslySetInnerHTML={{ __html: content }} />
				{authors.length
					? (
						<p>Author:&nbsp;
							{authors.map((author, index) => (
								<>
									<Link to={`/${author?.replace(/ /g, "").toLowerCase()}`}>
										{author}
									</Link>
									{
										index !== (authors.length - 1)
											? ", "
											: null
									}
								</>
							))}
						</p>
					)
					: null
				}
			</div>
			{(post?.fields?.category === "blog" || post?.node?.properties?.isCommentable?.value)
				? (
					<div>
						<hr />
						<CommentSection post={post} comments={comments} />
					</div>
				)
				: null
			}
		</>
	)
}