import React from "react";
import * as containerStyles from "./layout.module.less";
import Subfooter from "../components/subfooter";
import { useLocation, LocationProvider } from "@gatsbyjs/reach-router"
import { Helmet } from "react-helmet";
import { createInstance, MatomoProvider, useMatomo } from "@datapunt/matomo-tracker-react";
import Header from "./landing-page/header";
import Footer from "./landing-page/footer";
import cn from "classnames";
import * as landingPageStyle from "./landing-page/landing-page.module.less";
import * as s from "./landing-page/landing-video/landing-video.module.less";
import LinkedinIcon from "../media/svg/linkedin-square.svg";
import TwitterIcon from "../media/svg/twitter-square.svg";
import * as landingStyle from "./landing-page/landing.module.less";
const instance = createInstance({
	configurations: {
		disableCookies: true,
		setDoNotTrack: true,
		trackPageView: true,
	},
	linkTracking: true,
	siteId: `${ process.env.MATOMO_SITE_ID }`,
	srcUrl: `https://${ process.env.MATOMO_URL }/matomo.js`,
	trackerUrl: `https://${ process.env.MATOMO_URL }/matomo.php`,
	urlBase: `${ process.env.MATOMO_URL }`
})
const LayoutContent = ({
	children,
	hasSubfooter = true,
}) => {
	const location = useLocation();
	const lang = (location.pathname).replaceAll("/", "")
	const {
		trackPageView,
		enableLinkTracking
	} = useMatomo()
	enableLinkTracking()
	React.useEffect(() => {
		trackPageView()
	}, [trackPageView, location.pathname])
	const navElements = [
		{
		  text: "Portfolio",
		  href: "/portfolio"
		},
		{
		  text: "Team",
		  href: "/employees"
		},
		{
		  text: "Blog",
		  href: "/blog"
		},
		{
		  text: "Success Stories",
		  href: "/success-stories"
		}
	]
	return (
		<div className={cn(containerStyles.site, landingStyle.container)}>
			<Helmet
				htmlAttributes={ {
					lang: `${lang}`,
				} }
			>
				<meta charSet="utf-8"/>
				<meta name="description"
					  content="Team Parallax Website, Parallax IT GmbH"/>
				<title>Team Parallax</title>
			</Helmet>
			<Header
				navElements={navElements}
			/>
			<div className={ containerStyles.container }>
				{ children }
			</div>
			{ hasSubfooter && ( <Subfooter/>) }
			<Footer
				socialIcons={ [
					{
						icon: <LinkedinIcon/>,
						href: "https://www.linkedin.com/company/team-parallax"
					},
					{
						icon: <TwitterIcon/>,
						href: "https://twitter.com/InsOp_de"
					}
				] }
				links={ navElements }
				address="Magirus-Deutz-Straße 12, 89077 Baden&#x2011;Württemberg - Ulm"
				phone="+49 731 14 61 38 00"
			/>
		</div>
	)
}
const Layout = ({
	children,
	hasSubfooter = true,
}) => {
	return (
		<LocationProvider>
			<MatomoProvider value={ instance }>
				<LayoutContent
					hasSubfooter={ hasSubfooter }
				>
					{ children }
				</LayoutContent>
			</MatomoProvider>
		</LocationProvider>
	);
};
export default Layout;
