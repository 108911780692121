module.exports = {
    "webgl": {
        "name": "WebGL",
        "category": "client",
        "id": "webgl",
    },
    "canvas": {
        "name": "Canvas",
        "category": "client",
        "id": "canvas",
    },
    "sqlserver": {
        "name": "SQLServer",
        "category": "client",
        "id": "sqlserver",
    },
    "express": {
        "name": "express",
        "category": "client",
        "id": "express",
    },
    "openapi": {
        "name": "openAPI",
        "category": "client",
        "id": "openapi",
    },
    "graphql": {
        "name": "GraphQL",
        "category": "client",
        "id": "graphql",
    },
    "strapi": {
        "name": "strapi",
        "category": "server",
        "id": "strapi",
    },
    "html": {
        "name": "HTML",
        "category": "client",
        "id": "html",
    },
    "css": {
        "name": "CSS",
        "category": "client",
        "id": "css",
    },
    "jenkins": {
        "name": "Jenkins",
        "category": "util",
        "id": "jenkins",
    },
    "bash": {
        "name": "Bash",
        "category": "util",
        "id": "bash",
    },
    "minio": {
        "name": "MinIO",
        "category": "util",
        "id": "minio",
    },
    "javascript": {
        "name": "JavaScript",
        "category": "client",
        "id": "javascript",
    },
    "nextjs": {
        "name": "NextJs",
        "category": "client",
        "id": "nextjs",
    },
    "socket.io": {
        "name": "Socket.IO",
        "category": "client",
        "id": "socket.io",
    },
    "rabbitmq": {
        "name": "RabbitMQ",
        "category": "client",
        "id": "rabbitmq",
    },
    "npm": {
        "name": "npm",
        "category": "client",
        "id": "npm"
    },
    "flask": {
        "name": "Flask",
        "category": "client",
        "id": "flask"
    },
    "git": {
        "name": "Git",
        "category": "util",
        "id": "git"
    },
    "gitlab": {
        "name": "GitLab",
        "category": "util",
        "id": "gitlab"
    },
    "gitlab-ci": {
        "name": "GitLab CI",
        "category": "util",
        "id": "gitlab-ci"
    },
    "github": {
        "name": "Github",
        "category": "util",
        "id": "github"
    },
    "docker": {
        "name": "Docker",
        "category": "util",
        "id": "docker"
    },
    "intellij": {
        "name": "IntelliJ IDEA",
        "category": "util",
        "id": "intellij"
    },
    "java": {
        "name": "Java",
        "category": "server",
        "id": "java"
    },
    "markdown": {
        "name": "Markdown",
        "category": "util",
        "id": "markdown"
    },
    "mattermost": {
        "name": "Mattermost",
        "category": "util",
        "id": "mattermost"
    },
    "mongodb": {
        "name": "MongoDB",
        "category": "server",
        "id": "mongodb"
    },
    "nodejs": {
        "name": "Nodejs",
        "category": "server",
        "id": "nodejs"
    },
    "pycharm": {
        "name": "PyCharm",
        "category": "util",
        "id": "pycharm"
    },
    "python": {
        "name": "Python",
        "category": "server",
        "id": "python"
    },
    "react": {
        "name": "React",
        "category": "client",
        "id": "react"
    },
    "rust": {
        "name": "Rust",
        "category": "server",
        "id": "rust"
    },
    "slack": {
        "name": "Slack",
        "category": "util",
        "id": "slack"
    },
    "stackoverflow": {
        "name": "StackOverflow",
        "category": "util",
        "id": "stackoverflow"
    },
    "visualstudiocode": {
        "name": "Visual Studio Code",
        "category": "util",
        "id": "visualstudiocode"
    },
    "angularjs": {
        "name": "AngularJs",
        "category": "client",
        "id": "angular"
    },
    "angular2": {
        "name": "Angular",
        "category": "client",
        "id": "angular2"
    },
    "ansible": {
        "name": "Ansible",
        "category": "util",
        "id": "ansible"
    },
    "apachehttpserver": {
        "name": "Apache HTTP Server",
        "category": "server",
        "id": "apachehttpserver"
    },
    "typeorm": {
        "name": "Typeorm",
        "category": "server",
        "id": "typeorm"
    },
    "postgresql": {
        "name": "PostgreSQL",
        "category": "server",
        "id": "postgresql"
    },
    "nestjs": {
        "name": "Nestjs",
        "category": "server",
        "id": "nestjs"
    },
    "confluence": {
        "name": "Confluence",
        "category": "util",
        "id": "confluence"
    },
    "couchbase": {
        "name": "Couchbase",
        "category": "server",
        "id": "couchbase"
    },
    "dockercompose": {
        "name": "Docker Compose",
        "category": "util",
        "id": "dockercompose"
    },
    "expressjs": {
        "name": "ExpressJS",
        "category": "server",
        "id": "expressjs"
    },
    "gatsby": {
        "name": "Gatsby",
        "category": "client",
        "id": "gatsby"
    },
    "gulp": {
        "name": "gulp",
        "category": "util",
        "id": "gulp"
    },
    "html5": {
        "imagePath": "/html5.png",
        "name": "HTML5",
        "category": "client",
        "id": "html5"
    },
    "hugo": {
        "name": "Hugo",
        "category": "client",
        "id": "hugo"
    },
    "jira": {
        "name": "Jira",
        "category": "util",
        "id": "Jira"
    },
    "jquery": {
        "name": "jQuery",
        "category": "client",
        "id": "ansible"
    },
    "knexjs": {
        "name": "Knex.js",
        "category": "server",
        "id": "knexjs"
    },
    "kubernetes": {
        "name": "Kubernetes",
        "category": "util",
        "id": "kubernetes"
    },
    "less": {
        "name": "Less",
        "category": "client",
        "id": "less"
    },
    "meteor": {
        "name": "Meteor",
        "category": "server",
        "id": "meteor"
    },
    "mysql": {

        "name": "MySQL",
        "category": "server",
        "id": "mysql"
    },
    "nginx": {
        "name": "nginx",
        "category": "server",
        "id": "nginx"
    },
    "php": {
        "name": "PHP",
        "category": "server",
        "id": "php"
    },
    "phpstorm": {
        "name": "PhpStorm",
        "category": "util",
        "id": "phpstorm"
    },
    "postman": {
        "name": "Postman",
        "category": "util",
        "id": "postman"
    },
    "reduxjs": {
        "name": "Redux.js",
        "category": "client",
        "id": "reduxjs"
    },
    "sass": {
        "name": "Sass",
        "category": "client",
        "id": "sass"
    },
    "socketio": {
        "name": "Socket.io",
        "category": "server",
        "id": "socketio"
    },
    "sourcetree": {
        "name": "SourceTree",
        "category": "util",
        "id": "sourcetree"
    },
    "sublimetext": {
        "name": "Sublime text",
        "category": "util",
        "id": "sublimetext"
    },
    "trello": {
        "name": "Trello",
        "category": "util",
        "id": "trello"
    },
    "typescript": {
        "name": "TypeScript",
        "category": "client",
        "id": "typescript"
    },
    "uglifyjs": {
        "name": "UglifyJS",
        "category": "util",
        "id": "uglifyjs"
    },
    "virtualbox": {
        "name": "VirtualBox",
        "category": "util",
        "id": "virtualbox"
    },
    "yeoman": {
        "name": "Yeoman",
        "category": "util",
        "id": "yeoman"
    },
    "webstorm": {
        "name": "Webstorm",
        "category": "util",
        "id": "webstorm"
    },
    "vuejs" : {
        "name": "Vue",
        "category": "client",
        "id": "vuejs"
    },
    "mobx": {
        "name": "MobX",
        "category": "client",
        "id": "mobx"
    },
    "postcss": {
        "name": "PostCSS",
        "category": "client",
        "id": "postcss"
    },
    "aws": {
        "name": "AWS",
        "category": "util",
        "id": "aws"
    },
    "azure": {
        "name": "Azure",
        "category": "server",
        "id": "azure"
    },
    "googlecloud": {
        "name": "Google Cloud",
        "category": "util",
        "id": "googlecloud"
    },
    "microsoftteams": {
        "name": "Microsoft Teams",
        "category": "util",
        "id": "microsoftteams"
    },
    "webpack": {
        "name": "Webpack",
        "category": "util",
        "id": "webpack"
    },
    "lens": {
        "name": "Lens",
        "category": "util",
        "id": "lens"
    },
    "reactnative": {
        "name": "React Native",
        "category": "client",
        "id": "reactnative"
    },
    "androidstudio": {
        "name": "Android Studio",
        "category": "util",
        "id": "androidstudio"
    },
    "unity": {
        "name": "Unity",
        "category": "client",
        "id": "unity"
    },
    "ionic": {
        "name": "Ionic",
        "category": "client",
        "id": "ionic"
    },
    "electron": {
        "name": "Electron",
        "category": "client",
        "id": "electron"
    },
    "mocha": {
        "name": "Mocha",
        "category": "util",
        "id": "mocha"
    },
    "chai": {
        "name": "Chai",
        "category": "util",
        "id": "chai"
    },
    "go": {
        "name": "Go",
        "category": "server",
        "id": "go"
    },
    "csharp": {
        "name": "C#",
        "category": "server",
        "id": "csharp"
    },
    "cpp": {
        "name": "C++",
        "category": "server",
        "id": "cpp"
    },
    "redis": {
        "name": "Redis",
        "category": "server",
        "id": "redis"
    },
    "moodle": {
        "name": "Moodle",
        "category": "server",
        "id": "moodle"
    }
};
