import React from "react";
import { Link } from 'gatsby-plugin-react-i18next';
import * as Style from "../templates/blog.module.less";
export const BlogListEntry = ({
	node,
}) => {
	const slug = node.properties?.Slug.value || node.fields.slug
	const date = node.properties?.publishDate?.value.start || node.frontmatter.date
	const authors = node.properties?.Author.value.map(author => author.name) || [node.frontmatter?.author]
	return (
		<Link to={ `/${ slug }` }>
			<>
				<h3 className={ Style.headline }>{ node.title || node.frontmatter.title }</h3>
				<span
					className={ Style.date }>{ date } - by { authors.join(", ") }</span>
				<p>{ node.excerpt || node.childrenMarkdownRemark[0].excerpt }</p>
			</>
		</Link>
	)
}