// extracted by mini-css-extract-plugin
export var entry = "employees-module--entry--YEYGK";
export var container = "employees-module--container--qIdv6";
export var employeeWrap = "employees-module--employeeWrap--q+8D8";
export var preview = "employees-module--preview--PJp6r";
export var excerpt = "employees-module--excerpt--QkMvW";
export var degree = "employees-module--degree--LGcQl";
export var degElement = "employees-module--degElement--SDc1a";
export var image = "employees-module--image--gp66U";
export var bachelor = "employees-module--bachelor--HTp25";
export var master = "employees-module--master--7WRsK";
export var certificates = "employees-module--certificates--VjNv4";
export var cert = "employees-module--cert--sReRf";
export var title = "employees-module--title--W923s";