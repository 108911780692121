import React from "react";
import * as Style from "./badge.module.less";
const Badge = ({
    label
}) => {
    return (
        <div className={Style.badge}>
            <span className={Style.label}>{label}</span>
        </div>
    )
}
export default Badge;