// extracted by mini-css-extract-plugin
export var simplymeetWrapper = "simplymeet-module--simplymeet-wrapper--bOd+J";
export var staff = "simplymeet-module--staff--bxpdV";
export var staffBox = "simplymeet-module--staffBox--LTJWZ";
export var firstBox = "simplymeet-module--firstBox--e8U3B";
export var simplymeet = "simplymeet-module--simplymeet--4mD5f";
export var leftBox = "simplymeet-module--leftBox--zSRNy";
export var rightBox = "simplymeet-module--rightBox--yMLGk";
export var box = "simplymeet-module--box--qetCC";
export var frameBox = "simplymeet-module--frameBox--+7pNv";
export var heading = "simplymeet-module--heading--ybEyb";