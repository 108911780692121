import React from "react"
import HowToHelpService from "./how-to-help-services"
import * as s from "./help.module.less";
import * as landingPageStyle from "../landing-page.module.less"
import Pane from "../../pane/pane";
import PaneTitle from "../../pane/pane-title/pane-title";
import cn from "classnames"
const Help = ({
  heading,
  description,
  services,
  text
}) => {
  return (
    <Pane>
      <div className={ landingPageStyle.container }>
        <PaneTitle
          title={heading}
          description={description}
          isCentered
        />
        <div className={ s.services }>
          {services.map((service) => (
            <div className={ s.service } key={ service.title }>
              <HowToHelpService service={ service }/>
            </div>
          ))}
        </div>
        <p className={ cn(landingPageStyle.description, s.info) }>
          { text }
        </p>
      </div>
    </Pane>
  )
}
export default Help
