import React, { useEffect } from 'react';
import {graphql} from 'gatsby';
import {useTranslation} from "gatsby-plugin-react-i18next";
import { navigate } from "gatsby"
import { createVCardBlobUrl } from '../utils';
const VCardTemplate = ({data}) => {
    const frontmatter = data.profile.frontmatter
    const downlaodRef = React.useRef(null)
	const { t } = useTranslation()
    const vCardBlobUrl = createVCardBlobUrl(t, frontmatter.tel, frontmatter.name, frontmatter.dev_type, frontmatter.mobile)
    useEffect(() => {
        downlaodRef.current.click()
        navigate(`${data.profile.fields.slug}/`)

    })
    return (
        <>
           <a
            href={vCardBlobUrl}
            download={`${frontmatter.name.split(" ").join("-").toLowerCase()}.vcf`}
            ref={downlaodRef}
            style={{
                visibility: "hidden"
            }}
            />
        </>
    );
};
export default VCardTemplate
export const query = graphql`
  query($slug: String!, $language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
              node {
                  ns
                  data
                  language
              }
          }
      }
    profile: markdownRemark(
      fields: {
        slug: { eq: $slug } 
        language: { eq: $language } 
      }
    ) {
        fields {
            slug
            }
        frontmatter {
        name
        dev_type,
        tel,
        mobile
        }
    }
  }
`;
