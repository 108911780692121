import React, { useState } from "react"
import cn from "classnames"
import * as s from "./landing-video.module.less";
import {useTranslation} from "gatsby-plugin-react-i18next";
import * as landingPageStyle from "../landing-page.module.less"
import Video from "../video-pane/video";
import Header from "../header";
const LandingVideo = ({
	navElements,
	headerBtn,
	backgroundImageUrl,
	content,
	video,
	videoProps,
	videoErrormessage
}) => {
	const {
        t
    } = useTranslation()
	return (
		<div
			className={ s.landing }
			style={ {
				backgroundImage: `url(${backgroundImageUrl})`
			} }
		>
			<div className={ landingPageStyle.container }>
				<div className={ cn(s.content, {
					[s.hasVideo]: video
				}) }>
					<div className={ cn(landingPageStyle.container, s.headerContainer) }>
						{video && <Video
							video={ video }
							videoProps={ videoProps }
							videoErrormessage={ videoErrormessage }
							isLarge={ false }
							classnames={ s.video }
						/>}
						<div className={ s.textContent }>
							<h1 className={ s.heading }>{ content.heading }</h1>
							<p className={ s.description }>{ content.description }</p>
							{ content.btn && (
								<button
									className={ s.contentBtn }
									onClick={ content.btn.onClick }
								>
									<a href={ content.btn.href }>
										{ content.btn.text.toUpperCase() }
									</a>
								</button>
							)}
						</div>
					</div>

				</div>
			</div>
		</div>
	)
}
export default LandingVideo
