import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { BlogEntry } from "../components/blog-entry"
import NotFound from "../pages/404";
require("../styles/custom-syntax-hightlighting.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")
const BlogPostTemplate = ({ data, location, pageContext: { language } }) => {
	const posts = data.markdownRemark
		? data.notion.edges.concat(data.markdownRemark)
		: data.notion.edges
	const languagePosts = posts.filter(post => post?.fields?.language === language
		|| post?.node?.fields?.language === language)
	const post = languagePosts[0] ?? posts[0]
	if (!post) {
		return <NotFound />
	}
	const comments = data.allCommentsYaml ? data.allCommentsYaml.edges : null;
	return (
		<Layout>
			<BlogEntry
				post={post}
				comments={comments}
			/>
		</Layout>
	);
};
export default BlogPostTemplate
export const query = graphql`
  query($slug: String!, $language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
              node {
                  ns
                  data
                  language
              }
          }
      }
	markdownRemark(fields: {
		slug: { eq: $slug }
		language: { eq: $language }
    }) {
	  html
	  frontmatter {
		title
		subtitle
		date(formatString: "DD MMMM, YYYY")
		author
		category
	  }
	  fields {
		language
		slug
	  }
	}
	allCommentsYaml(filter: { slug: { eq: $slug } }) {
	  edges {
		comment: node {
		  id
		  comment
		  email
		  name
		  slug
		  date
		  parentId
		}
	  }
	}
	notion: allNotion(
		filter: {
            fields: {
                slug: { eq: $slug }
            }
			properties: {
				Publish: {
					value: {eq: true}
				}
			}
		}
	) {
	  edges {
		node {
		  title
		  childrenMarkdownRemark {
			html
		  }
		  properties {
			publishDate {
			  value {
				start(formatString: "DD MMMM, YYYY")
			  }
			}
			Author {
			  value {
                  id
                  name
              }
			}
			isCommentable {
				value
			}
		  }
		  fields {
			slug
		  }
		}
	  }
	}
  }
`;