import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import Render from "../components/render";
import Technology from "../components/technology-pane/index";
import ProjectOverviewHorizontal from "../components/projectOverviewHorizontal";
import ProductOverview from "../components/product-overview/index"
import CustomersOverview from "../components/customer-overview/customersOverview"
import HeaderSlider from "../components/header-slider/index";
import VideoPane from "../components/landing-page/video-pane";
import officeImg from "../media/landing-page/office.jpg";
import Help from "../components/landing-page/help";
import automationIcon from "../media/landing-page/automation-icon.png";
import modernizationIcon from "../media/landing-page/modernization-icon.png";
import WhyChoose from "../components/landing-page/why-choose";
import About from "../components/landing-page/about";
import whoWeAreImg from "../media/landing-page/how-we-are.png";
import Certificates from "../components/landing-page/certificates";
import Testimonials from "../components/landing-page/testimonials";
import Simplymeet from "../components/landing-page/simplymeet";
import teamParallaxVideo from "../media/videos/team_parallax.mp4"
import { extractContentBetweenTags, splitStringBeforeTag } from "../utils";
import { useI18next } from "gatsby-plugin-react-i18next";
import * as landingStyle from "../components/landing-page/landing.module.less";
import { Helmet } from "react-helmet";
import LinkedinIcon from "../media/svg/linkedin-square.svg";
import TwitterIcon from "../media/svg/twitter-square.svg";
import Footer from "../components/landing-page/footer";
import Header from "../components/landing-page/header";
import Favicon from "../media/favicon/x.ico"
import cn from "classnames";
import * as landingPageStyle from "../components/landing-page/landing-page.module.less";
import * as s from "../components/landing-page/landing-video/landing-video.module.less";

const LandingPage =  ({ data }) => {
  const {
    intro,
    products,
    projects,
    customers,
    testimonials,
    technology,
    certificates
  } = data
	const {
		language
	} = useI18next()
	const navElements = [
		{
			text: "Portfolio",
			href: "/portfolio"
		},
		{
			text: "Team",
			href: "/employees"
		},
		{
			text: "Blog",
			href: "/blog"
		},
		{
			text: "Success Stories",
			href: "/success-stories"
		}
	]
  return (
	  <div className={landingStyle.container}>
          <Helmet>
              <title>Für alle Unternehmer | Team Parallax</title>
              <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
          </Helmet>
          <Header
              navElements={navElements}
          />
          <HeaderSlider data={products.edges}/>
          <ProductOverview data={products.edges}/>
          <CustomersOverview data={customers.edges} />
          <Help
              heading="WIE WIR HELFEN"
              description="Im Allgemeinen wollen wir, dass unsere Kunden mit unseren Dienstleistungen Geld verdienen oder sparen. Wir sind spezialisiert auf Automatisierung und Modernisierung - den Umbau alter Software in Webanwendungen."
              services={ [
                  {
                      icon: automationIcon,
                      title: "AUTOMATION",
                      description: "Damit unsere Kunden nicht viel Geld für unnötiges und ineffizientes Personal bezahlen müssen"
                  },
                  {
                      icon: modernizationIcon,
                      title: "MODERNISIERUNG",
                      description: "Damit ihre alte Software in neuem Glanz erstrahlt (bessere UX, schneller, weniger Wartungsaufwand, von allen Geräten aus zugänglich usw.)"
                  }
              ] }
              text="Wir übernehmen die volle Verantwortung für den Erfolg unserer Kunden"
          />
          <WhyChoose
              header={"Warum"}
              teamName="TEAM PARALLAX"
              description="Wir nutzen die modernsten Technologien, um Unternehmen bei der Skalierung ihrer Fähigkeiten in den Bereichen Entwicklung, Design und digitales Marketing zu unterstützen."
              backgroundImage={ officeImg }
          />
          <About
              heading="WER WIR SIND"
              description1="Als junges IT-Unternehmen freuen wir uns darauf, Ihre Ideen umzusetzen und/oder sie gemeinsam mit Ihnen zu entwickeln."
              description2="Wir lieben Herausforderungen!"
              description3="Ob Web- oder mobile Anwendungen, E-Commerce, sicherheitsrelevante Anwendungen, Gamification, Effizienz- und Produktivitätssteigerungen - wir finden eine Lösung, die zu Ihrem Geschäft und Ihren Zielgruppen passt."
              btn={ {
                  text: "MEET OUR TEAM",
                  onClick: () => undefined,
                  href: "/de/employees/"
              } }
              img={ whoWeAreImg }
          />
          <VideoPane
              heading={"Kurz und Knapp"}
              description={"Es braucht nicht lange, um einen Eindruck zu hinterlassen. In nur 90 Sekunden zeigen wir Ihnen, warum wir die richtige Wahl für Sie sind."}
              video={ teamParallaxVideo }
              videoProps={ {
                  controls: true,
                  muted: false
              } }
              isFullSize={ true }
              videoErrormessage={ "Ihr Browser kann dieses Video nicht wiedergeben. Dieses Video zeigt den Big Buck Bunny Film." }
              backgroundImage={ officeImg }
          />
          { certificates && (
              <Certificates
                  heading="Wir sind zertifiziert"
                  description="Unser Team verfügt über Zertifikate in den verschiedensten Bereichen um die beste Expertise für unsere Kunden zu haben."
                  certificates={ certificates } />
          )}
          <Testimonials
              heading="TESTIMONIALS"
              description="Das sagen unsere Kunden über uns"
              testimonials={testimonials.edges.filter(({node}) => node.fields.language === language).map((item) => {
                  return {
                      img: item.node.frontmatter.imagePath.childImageSharp.fluid,
                      name: item.node.frontmatter.name,
                      company: item.node.frontmatter.company,
                      description: extractContentBetweenTags(splitStringBeforeTag(item.node.html, "h2")
                          .slice(-1)[0], "p"),
                      href: item.node.frontmatter.href
                  }})
              }
          />
          <Simplymeet
              heading={"LET’S GET STARTED"}
              description={"Ein Projekt oder eine Partnerschaft ist geplant? Vereinbaren Sie direkt ihren Termin zu einem ersten Kennenlernen mit Team Parallax"}
          />
          <Footer
              socialIcons={ [
                  {
                      icon: <LinkedinIcon/>,
                      href: "https://www.linkedin.com/company/team-parallax"
                  },
                  {
                      icon: <TwitterIcon/>,
                      href: "https://twitter.com/InsOp_de"
                  }
              ] }
              links={ navElements }
              address="Magirus-Deutz-Straße 12, 89077 Ulm - Baden&#x2011;Württemberg"
              phone="+49 731 14 61 38 00"
          />
    </div>
  );
};
export default LandingPage
export const query = graphql`
  query ($language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    intro: markdownRemark(
      frontmatter: {
        title: { eq: "intro" }
      }
      fields: {
        language: { eq: $language }
      }
    ) {
      htmlAst
      frontmatter {
        title
      }
    }
    profiles: allMarkdownRemark (
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        frontmatter: {  category: { eq: "profile" }}
        fields: {
          language: { eq: $language }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            name
            dev_type
            expertise
            degree
            technology
            imagePath {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { 
          language: { eq: $language }
        }
        frontmatter: {
          isDraft: { ne: true }
          category: { eq: "projects" }
        }
      }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            preview {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    products: allMarkdownRemark(
        filter: {
            fields: {
              language: { eq: $language }
            }
            frontmatter: {
              category: { eq: "portfolio" }
              isDraft: { ne: true }
            }
        }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            servicesList
            servicesText
            smallImagePath {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 300) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
              slug
          }
          excerpt
        }
      }
    }
    technology: allMarkdownRemark(
        filter: {
          fields: {
            language: { eq: $language }
          }
          frontmatter: {
            category: { eq: "technologyStack" }
          }
        } 
    ){
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            title
            category
            imagePath {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
          }
        }
      }
    }
    customers: allMarkdownRemark (
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        frontmatter: {
          category: { eq: "customers" }
          isDraft: { ne: true }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            name
            link
            imagePath {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    testimonials: allMarkdownRemark(
          filter: {
			fields: {
					language: { eq: $language }
				}
              frontmatter: {
                  category: {eq: "testimonial"}
                  publish: {eq: true }
              }
          }
      ) {
          edges {
              node {
                  html
                  fields {
                      language
                      slug
                  }
                  frontmatter {
                      name
                      company
                      href
                      pages
                      imagePath {
                          childImageSharp {
                              fluid {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
              }
          }
      }
  }
`;
