import React from "react";
import cn from "classnames";
import * as s from "./pane-title.module.less"
const PaneTitle = ({
	title,
	description,
	isCentered = false,
	isWhite = false
}) => {
	return (
		<div className={ cn(s.paneTitle, {
			[s.center]: isCentered,
			[s.white]: isWhite
		}) }>
			<h2 className={ s.heading }>
				{ title }
			</h2>
			{ description && (
				<p className={ s.description }>
					{ description }
				</p>
			)}
		</div>
	)
}
export default PaneTitle
