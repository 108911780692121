import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout";
import Img from "gatsby-image";
import * as Style from "./employees.module.less";
import cn from "classnames";
import * as PStyle from "../templates/profile.module.less";
import Favicon from "../media/favicon/x.ico";
const EmployeesPage = ({ data }) => {
	const {
        t
    } = useTranslation()
    const {
        certificateImages,
        profiles
    } = data
	return (
		<Layout>
			<Helmet>
				<title> Employees | Team Parallax</title>
                <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
            </Helmet>
			<div>
				<h4>{ t("employees.team") }</h4>
				<div className={ Style.employeeWrap } >
					{ profiles.edges.map(({ node }) => {
						const frontmatter = node.frontmatter
						return (
							<div key={node.id} className={Style.entry}>
								<Link to={node.fields.slug}>
									<div className={Style.container}>
										{frontmatter.imagePath ? (
											<Img
												className={Style.preview}
												fluid={frontmatter.imagePath.childImageSharp.fluid}
											/>
										) : (
											""
										)}
										<div className={Style.excerpt}>
											<h4>{frontmatter.name}</h4>
											{frontmatter.dev_type}
											<br/>
											<div className={Style.degree}>
												{frontmatter.degree.map((deg) => {
													return (
														<div className={Style.degElement}>
															<div className={ cn(Style.image, {
																[Style.bachelor]: deg.slice(0, 1) === "B" ,
																[Style.master]: deg.slice(0, 1) === "M"
															})}/>
															{deg}
														</div>
													)
												})}
											</div>
                                            <div className={ Style.certificates }>
                                                {frontmatter.certificates.length > 0 && (
                                                    frontmatter.certificates.map((cert) => {
                                                            const certification = certificateImages.edges
                                                                .find((e) => e.node.frontmatter.short === cert).node.frontmatter
                                                            return (
                                                                <span
																	title={certification.short.toUpperCase()}
                                                                    className={Style.cert}
                                                                >
                                                                    <Img
																		title={certification.short.toUpperCase()}
                                                                        alt={cert}
                                                                        fluid={certification.imagePath.childImageSharp.fluid}
                                                                        className={Style.image}
                                                                    />
                                                                </span>
                                                            )
                                                        }
                                                    )
                                                )}
                                            </div>
											<br/>
										</div>
									</div>
								</Link>
							</div>
						)
					}) }
				</div>
			</div>
		</Layout>
	)
}
export default EmployeesPage
export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        },
        profiles: allMarkdownRemark(
            sort: {fields: [frontmatter___order], order: ASC}
            filter: {
                frontmatter: {  category: { eq: "profile" }, isInactive: {ne: true}}
                fields: {
                    language: { eq: $language }
                }
            }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        name
                        expertise
                        dev_type
                        degree
						certificates
                        imagePath {
                            childImageSharp {
                                fluid(maxWidth: 100, maxHeight: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        date(formatString: "DD MMMM, YYYY")
                    }
                    fields {
                        slug
                    }
                    excerpt(pruneLength: 80)
                }
            }
        }
        certificateImages: allMarkdownRemark (
            sort: { fields: [frontmatter___order], order: ASC }
            filter: {
                frontmatter: {
                    isDraft: { ne: true }
                    category: { eq: "certificates" }
                }
            }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        name
                        short
                        url
                        imagePath {
                            childImageSharp {
                                fluid(maxWidth: 200) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;
