import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import './alice-carousel.less';
import * as Style from "./slider.module.less"
import classNames from "classnames";
export const Slider = ({
	items,
	infinite,
	interfaceColor,
	autoPlay,
	autoPlayInterval,
	animationType,
	animationDuration,
	activeIndex,
	animationEasingFunction,
	autoHeight,
	autoWidth,
	autoPlayControls,
	autoPlayDirection,
	autoPlayStrategy,
	controlsStrategy,
	className,
	disableButtonsControls,
	disableDotsControls,
	disableSlideInfo,
	innerWidth,
	keyboardNavigation,
	mouseTracking,
	name,
	paddingLeft,
	paddingRight,
	responsive,
	swipeDelta,
	swipeExtraPadding,
	ssrSilentMode,
	touchTracking,
	touchMoveDefaultEvents,
	onInitialized,
	onResized,
	onResizeEvent,
	onSlideChange,
	onSlideChanged
}) => {
	return (
		<div
			className={classNames(Style.slider, className)}>
			<AliceCarousel
				items={ items }
				infinite={ infinite }
				autoPlay={ autoPlay }
				autoPlayInterval={ autoPlayInterval }
				animationType={ animationType }
				animationDuration={ animationDuration }
				activeIndex={ activeIndex }
				animationEasingFunction={ animationEasingFunction }
				autoHeight={ autoHeight }
				autoWidth={ autoWidth }
				autoPlayControls={ autoPlayControls }
				autoPlayDirection={ autoPlayDirection }
				autoPlayStrategy={ autoPlayStrategy }
				controlsStrategy={ controlsStrategy }
				disableButtonsControls={ disableButtonsControls }
				disableDotsControls={ disableDotsControls }
				disableSlideInfo={ disableSlideInfo }
				innerWidth={ innerWidth }
				keyboardNavigation={ keyboardNavigation }
				mouseTracking={ mouseTracking }
				name={ name }
				paddingLeft={ paddingLeft}
				paddingRight={ paddingRight }
				responsive={ responsive }
				swipeDelta={ swipeDelta }
				swipeExtraPadding={ swipeExtraPadding }
				ssrSilentMode={ ssrSilentMode }
				touchTracking={ touchTracking }
				touchMoveDefaultEvents={ touchMoveDefaultEvents }
				onInitialized={ onInitialized }
				onResized={ onResized }
				onResizeEvent={ onResizeEvent }
				onSlideChange={ onSlideChange }
				onSlideChanged={ onSlideChanged }
			/>
		</div>
	)
}
export default Slider