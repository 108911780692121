import React from "react";
import { graphql } from "gatsby";
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout";
import Img from "gatsby-image";
import * as Style from "./projects.module.less";
import { Helmet } from "react-helmet";
import Favicon from "../media/favicon/x.ico";
const ProjectsPage = ({ data }) => {
    const { t } = useTranslation()
  return (
    <Layout>
      <Helmet>
        <title>{t("projects.projectTitle")}</title>
          <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
      </Helmet>
      <div className={ Style.projectsContainer }>
        <h4>{data.projects.totalCount} {t("projects.projects")}</h4>
        {data.projects.edges.map(({ node }) => (
          <div key={node.id}>
            <Link className={Style.post} to={node.fields.slug}>
              <h3>{node.frontmatter.title}</h3>
              <i>{node.frontmatter.subtitle}</i>
              <span className={Style.date}>{node.frontmatter.date}</span>
              <div className={Style.description}>
                {node.frontmatter.preview ? (
                  <Img
                    className={Style.preview}
                    fluid={node.frontmatter.preview.childImageSharp.fluid}
                  />
                ) : (
                  ""
                )}
                <div className={Style.excerpt}>
                  <p>{node.excerpt}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  );
};
export default ProjectsPage
export const query = graphql`
  query($language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
              node {
                  ns
                  data
                  language
              }
          }
      }
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: {
          language: { eq: $language }
        }
        frontmatter: {
          category: { eq: "projects" }
          isDraft: { ne: true }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            preview {
              childImageSharp {
                fluid(maxWidth: 100, maxHeight: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
