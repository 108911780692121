import React from "react"
import * as landingStyle from "./landing.module.less"
import officeImg from "../../media/landing-page/office.jpg"
import whoWeAreImg from "../../media/landing-page/how-we-are.png"
import automationIcon from "../../media/landing-page/automation-icon.png"
import modernizationIcon from "../../media/landing-page/modernization-icon.png"
import TwitterIcon from "../../media/svg/twitter-square.svg"
import {useI18next} from "gatsby-plugin-react-i18next";
import LinkedinIcon from "../../media/svg/linkedin-square.svg"
import landingImg from "../../media/landing-page/landing.jpg"
import Services from "./services"
import WhyChoose from "./why-choose"
import Footer from "./footer"
import About from "./about"
import redTeam from "../../media/landing-page/team_red.png"
import {extractContentBetweenTags, splitStringBeforeTag} from "../../utils";
import Help from "./help"
import Contact from "./contact"
import { graphql, StaticQuery } from "gatsby";
import LandingVideo from "./landing-video";
import Certificates from "./certificates";
import VideoPane from "./video-pane";
import Simplymeet from "./simplymeet";
import Testimonials from "./testimonials";
import {Helmet} from "react-helmet";
import Header from "./header";
const AbstractLandingPage = ({
    services,
    landingContent,
    certificates,
    testimonials,
    video
}) => {
	const {
		language
	} = useI18next()
    const navElements = [
        {
          text: "Portfolio",
          href: "/portfolio"
        },
        {
          text: "Team",
          href: "/employees"
        },
        {
          text: "Blog",
          href: "/blog"
        },
        {
            text: "Success Stories",
            href: "/success-stories"
        }
    ]
    return (
        <StaticQuery
            query={graphql`
    query {
        successStory: allMarkdownRemark(filter: {frontmatter: {category: {eq: "successStory"}}}) {
            edges {
              node {
                html
                fields {
                  language
                  slug
                }
                frontmatter {
                  companyName 
                  publish
                  imagePath {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
    
    }
            `}
            render={ data => {
                // const filteredSuccesStories = data.successStory.edges.filter(({node}) => node.fields.language === language && node.frontmatter.publish)
                // const testimonials = filteredSuccesStories.map((item) => {
                //     return  {
                //         img: item.node.frontmatter.imagePath.childImageSharp?.fluid?.src,
                //         name: item.node.fields.companyName,
                //         description: extractContentBetweenTags(splitStringBeforeTag(item.node.html, "h2").slice(-1)[0], "p"),
                //         href: item.node.fields.slug
                //     }
                // })
                return(
                    <div className={landingStyle.container}>
                        <Helmet>
                            <title>Für alle Unternehmer | Team Parallax</title>
                        </Helmet>
                        <Header
                            navElements={navElements}
                        />
                        <LandingVideo
                            navElements={navElements}
                            backgroundImageUrl={ landingImg }
                            headerBtn={ {
                            text: "Contact",
                            onClick: () => undefined,
                            href: "/contact"
                            } }
                            content={ landingContent }
                            video={ video }
                            videoProps={ {
                                controls: true,
                                muted: true
                            } }
                            videoErrormessage={ "Your browser cannot play this video. This video shows the Big Buck Bunny movie." }

                        />
                        <Services
                            heading="WHAT WE OFFER"
                            description="We collaborate with businesses to create memorable experiences."
                            services={services}
                            btn={ {
                            text: "OTHER SERVICES",
                            href: "/portfolio"
                            } }
                        />
                        <WhyChoose
                            teamName="TEAM PARALLAX"
                            description="We use the most modern technologies to help businesses scale their development, design & digital marketing capabilities"
                            backgroundImage={ officeImg }
                        />
                        <About
                            heading="WHO WE ARE"
                            description1="As a young IT company, we look forward to implementing your ideas and/or developing them together with you."
                            description2="We love challenges!"
                            description3="Whether web or mobile applications, ecommerce, security related applications, gamification, efficiency and productivity improvements - we will find a solution that fits your business and your target groups."
                            btn={ {
                            text: "MEET OUR TEAM",
                            onClick: () => undefined,
                            href: "/employees/"
                            } }
                            img={ whoWeAreImg }
                        />
                        { certificates && (
                            <Certificates
                                heading="We are certified"
                                description="Our team has certificates in various fields to have the best expertise for our clients."
                                certificates={ certificates }
                            />
                        )}
                        { video && (
                            <VideoPane
                                heading={"Lorem Ipsum"}
                                description={"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua"}
                                video={ video }
                                videoProps={ {
                                    controls: true,
                                    muted: true
                                } }
                                videoErrormessage={ "Your browser cannot play this video. This video shows the Big Buck Bunny movie." }
                                backgroundImage={ officeImg }
                            />
                        )}
                        <Simplymeet
                            heading={"LET’S GET STARTED"}
                            description={"Ein Projekt oder eine Partnerschaft ist geplant? Vereinbaren Sie direkt ihren Termin zu einem ersten Kennenlernen mit Team Parallax"}
                        />
                        <Help
                            heading="HOW WE HELP PEOPLE"
                            description="In general, we want our customers to get money or save money with our services. We specialize in automation and modernization - rebuilding old software into web apps."
                            services={ [
                            {
                                icon: automationIcon,
                                title: "AUTOMATION",
                                description: "So that our customers don't have to pay much for unnecessary and unefficient staff"
                            },
                            {
                                icon: modernizationIcon,
                                title: "MODERNIZATION",
                                description: "So that their old software blooms into new splendor (better UX, faster, less maintenance, accessible from all devices etc.)"
                            }
                            ] }
                            text="We like to take full responsibility for our client's success"
                        />
                        <Testimonials
                            heading="TESTIMONIALS"
                            description="Das sagen unsere Kunden über uns"
                            testimonials={testimonials}
                        />
                        <Contact
                            heading="Let's Get Started"
                            description="Have a project or partnership in mind? Contact us today for a free consultation."
                            btn={ {
                            title: "Contact Us",
                            onClick: () => undefined,
                            href: ""
                            } }
                            img={ redTeam }
                        />

                        <Footer
                            socialIcons={ [
                            {
                                icon: <LinkedinIcon/>,
                                href: "https://www.linkedin.com/company/team-parallax"
                            },
                            {
                                icon: <TwitterIcon/>,
                                href: "https://twitter.com/InsOp_de"
                            }
                            ] }
                            links={ navElements }
                            address="Magirus-Deutz-Straße 12, 89077 Baden&#x2011;Württemberg - Ulm"
                            phone="+49 731 14 61 38 00"
                        />

                    </div>
            )}}
        >

        </StaticQuery>
    )
}
export default AbstractLandingPage
