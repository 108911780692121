import React from "react";
import Layout from "../components/layout";
import {Helmet} from "react-helmet";
import { graphql } from "gatsby";
import { Typeform } from "../components/typeform";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { getContactTypeformId } from "../utils";
import Render from "../components/render";
import Simplymeet from "../components/landing-page/simplymeet";
import * as landingStyle from "../components/landing-page/landing.module.less";
import Header from "../components/landing-page/header";
import LinkedinIcon from "../media/svg/linkedin-square.svg";
import TwitterIcon from "../media/svg/twitter-square.svg";
import Footer from "../components/landing-page/footer";
import Favicon from "../media/favicon/x.ico";
const Contact = ({data}) => {
    const {
        t,
		i18n: {
			language
		}
	} = useTranslation()
	const navElements = [
		{
			text: "Portfolio",
			href: "/portfolio"
		},
		{
			text: "Team",
			href: "/employees"
		},
		{
			text: "Blog",
			href: "/blog"
		},
		{
			text: "Success Stories",
			href: "/success-stories"
		}
	]
    const customTypeFormId = getContactTypeformId(language)
    return (
		<div className={landingStyle.container}>
			<Helmet>
				<title>Contact | Team Parallax</title>
				<link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
			</Helmet>
			<Header
				navElements={navElements}
			/>
			{
				customTypeFormId
					? (
						<Typeform
							type="contact"
							typeformId={customTypeFormId}
						/>
					)
					: (
						<Simplymeet
							heading={"LET’S GET STARTED"}
							description={"Ein Projekt oder eine Partnerschaft ist geplant? Vereinbaren Sie direkt ihren Termin zu einem ersten Kennenlernen mit Team Parallax"}
						/>
					)
					// : (
					//     <div>
					//         <h1>{t("button.contactButton")}</h1>
					//         { Render(data.contact.htmlAst) }
					//     </div>
					// )
			}
			<Footer
				socialIcons={ [
					{
						icon: <LinkedinIcon/>,
						href: "https://www.linkedin.com/company/team-parallax"
					},
					{
						icon: <TwitterIcon/>,
						href: "https://twitter.com/InsOp_de"
					}
				] }
				links={ navElements }
				address="Magirus-Deutz-Straße 12, 89077 Ulm - Baden&#x2011;Württemberg"
				phone="+49 731 14 61 38 00"
			/>
		</div>
);
};
export default Contact;
export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        contact: markdownRemark(
            frontmatter: {
              title: { eq: "Contact" }
            }
            fields: {
              language: { eq: $language }
            }
          ) {
            htmlAst
            frontmatter {
              title
            }
        }
    }
`;
