// extracted by mini-css-extract-plugin
export var headerContainer = "header-slider-module--headerContainer--Sebr6";
export var particlesContainer = "header-slider-module--particlesContainer--KpKh1";
export var content = "header-slider-module--content--MqmJm";
export var title = "header-slider-module--title--J4B7S";
export var sliderContainer = "header-slider-module--sliderContainer--eH2io";
export var headerItem = "header-slider-module--headerItem--htj-2";
export var link = "header-slider-module--link--vGv7J";
export var itemContainer = "header-slider-module--itemContainer--yrg3C";
export var description = "header-slider-module--description--CixFD";
export var imageContainer = "header-slider-module--imageContainer--psggO";
export var plate = "header-slider-module--plate--q2NEN";
export var image = "header-slider-module--image--UmgA5";