import React from "react";
import { graphql } from "gatsby";
import { BlogListEntry } from "../components/blog-list-entry";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import * as Style from "./blog.module.less";
import { PageNavigation } from "../components/page-navigation";
const BlogListTemplate =  ({ data, pageContext }) => {
	const isBlogPostWithMarkdownEnabled = pageContext.isBlogPostWithMarkdownEnabled
	let totalPostCount = data.notion.totalCount
	const combinedArticles = data.notion.edges
	if (isBlogPostWithMarkdownEnabled) {
		totalPostCount += data.articles.totalCount
		combinedArticles.push(...data.articles.edges)
	}
	const sortedArticles = combinedArticles.sort(function (a, b) {
		a = new Date(a.node.properties?.publishDate.value.start ?? a.node.frontmatter.date);
		b = new Date(b.node.properties?.publishDate.value.start ?? b.node.frontmatter.date);
		return a > b ? -1 : a < b ? 1 : 0;
	})
	return (
		<Layout>
			<Helmet>
				<title>Blog | Team Parallax</title>
			</Helmet>
			<div
				className={Style.wrapper}
			>
				<h4>{totalPostCount} Posts</h4>
				{
					sortedArticles.map(({ node }) => {
						return (
							<BlogListEntry
								node={node}
							/>
						)
					})
				}
			</div>
			<PageNavigation
				pageContext={pageContext}
				totalPostCount={totalPostCount}
			/>
		</Layout>
	);
};
export default BlogListTemplate
export const query = graphql`
    query blogListQuery(
        $skip: Int!,
        $limit: Int!,
        $language: String!
    ){
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        notion: allNotion(
            sort: {
                fields: properties___publishDate___value___start,
				order: DESC
            }
            filter: {
                properties:{
					publishDate:{
						value: {
							start: { ne: null }
						}
					},
					Publish: {
						value: { eq: true }
					}
				}
            }
            limit: $limit
            skip: $skip
        ) {
            totalCount
            edges {
                node {
                    title
                    fields {
                        slug
                    }
                    properties {
                        publishDate {
                            value {
                                start(formatString: "DD MMMM, YYYY")
                            }
                        }
                        Author {
                            value {
                                id
                                name
                            }
                        }
                        Slug {
                            value
                        }
                    }
                    childrenMarkdownRemark {
                        excerpt
                    }
                }
            }
        }
    }
`;
