import React from 'react';
import Img from 'gatsby-image';
import techStack from '../data/technology';
import * as Style from './technology.module.less';

const TechnologyStack = ({ technologies, profileTechnologies, profile, personalStack }) => {
    /* creating separate Arrays for different Technology categories */
    /* Client */
    const clientTechnology = Object.entries(techStack)
        .filter(([key, value]) => value.category === "client")
        .map(([key, value]) => key);
    /* Server */
    const serverTechnology = Object.entries(techStack)
        .filter(([key, value]) => value.category === "server")
        .map(([key, value]) => key);
    /* Utility/Tools */
    const utilTechnology = Object.entries(techStack)
        .filter(([key, value]) => value.category === "util")
        .map(([key, value]) => key);
	const allProfilesTechStack = profileTechnologies.reduce((result, {node}) => result.concat(node.frontmatter.technology), []);
	function renderStackElement(techList, technology, isProfile, personalStack) {
		return techList.includes(technology.node.name)
			? (isProfile ?
				/* Profiles tech stack */
				(personalStack.includes(technology.node.name)
					? (
						<div className={ Style.elem } key={ technology.node.name }>
							<div className={ Style.img }>
								<Img alt={ technology.node.name } fluid={ technology.node.childImageSharp.fluid }/>
							</div>
							<p className={ Style.techLabel }>{ techStack[technology.node.name].name }</p>
						</div>
					)
					/* should not be rendered because not in personal stack */
					: null)
				/* rendered as part of landing page - concatenation of all profiles' tech stacks*/
				: (allProfilesTechStack.includes(technology.node.name)
					? (
						<div className={ Style.elem } key={ technology.node.name }>
							<div className={ Style.img }>
								<Img alt={ technology.node.name } fluid={ technology.node.childImageSharp.fluid }/>
							</div>
							<p className={ Style.techLabel }>{ techStack[technology.node.name].name }</p>
						</div>
					)
					: null))
			/* not rendered because not in any valid category */
			: null;
	}
	function renderStack(techList, isProfile) {
		return (
			<div className={ Style.stack }>
				{
					isProfile
						? Object.entries(technologies).map(([name, technology]) => (
							/* Checking if current technology of data is content of the stack category
							 * that is rendered (client, server, util/tools)
							 * and additionally check if technology is used by employee.
							 */
							renderStackElement(techList, technology, true, personalStack)
						))
						: Object.entries(technologies).map(([name, technology]) => (
							renderStackElement(techList, technology, false, personalStack)
						))
				}
			</div>
		)
	}
    return (
        <div>
            <div>
                {/* Bringing all technology images to landing page. */}
                <h3> Client </h3>
                {renderStack(clientTechnology, profile)}
                {/* Adding the server-technologies */}
                <h3> Server </h3>
                {renderStack(serverTechnology, profile)}
                {/* Adding utility stuff and other tools to stack */}
                <h3> Utility/Tools </h3>
                {renderStack(utilTechnology, profile)}
            </div>
        </div>
    );
};
export default TechnologyStack
