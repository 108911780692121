import React from "react";
import classNames from 'classnames';
import Img from "gatsby-image";
import * as Style from "./customerOverview.module.less"
import ResponsiveCarousel from "../responsive-carousel"
import {useTranslation} from "gatsby-plugin-react-i18next";
import Pane from "../pane/pane";
import PaneTitle from "../pane/pane-title/pane-title";
function renderCustomerNode(node) {
	return (
		<div
			key={ node.id }
		>
			<a
				href={ node.frontmatter.link }
				rel={"nofollow noreferrer"}
				target={"_blank"}
			>
				<div className={ Style.customer }>
					<Img
						className={ Style.image }
						fadeIn={ false }
						loading={"eager"}
						imgStyle={{
							objectFit: "contain"
						}}
						alt={ node.frontmatter.name }
						fluid={ node.frontmatter.imagePath.childImageSharp.fluid }
					/>
				</div>
			</a>
		</div>
	);
}
const CustomerOverview = ({ data, overwriteWidth }) => {
	const { t } = useTranslation()
	return (
		<Pane>
			<div className={ classNames(Style.flexbox, Style.viewwidth, {
				[Style.overwriteWidth]: overwriteWidth
			}) }>
				<PaneTitle
					title={ t("customers.ourCustomers") }
					isCentered
				/>
				<div className={ Style.customers }>
					<ResponsiveCarousel
						shouldAutoPlay
					>
						{ data.map(({ node }) => (
							node.frontmatter.imagePath && (
								renderCustomerNode(node)
							)
						)) }
					</ResponsiveCarousel>
				</div>
			</div>
		</Pane>
	);
};
export default CustomerOverview
