import React from "react";
import * as footerStyles from "./subfooter.module.less";
import { useLocation } from "@gatsbyjs/reach-router";
import { Typeform } from "./typeform";
import { getContactTypeformId } from "../utils";
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
const Subfooter = () => {
	const location = useLocation();
	const {
		t,
		i18n: {
			language
		}
	} = useTranslation()
	const customTypeFormId = getContactTypeformId(language)
	const discordInviteLink = "https://discord.gg/JrSg9t3qP7"
	return (
		<div
			hidden={ location.pathname === "/contact" }
			className={ footerStyles.subfooter }
		>
			<div className={ footerStyles.content }>
				<p className={ footerStyles.description }>
					{ t("footer.contact") }
				</p>
				<div className={footerStyles.buttons}>
					{
						customTypeFormId
							? (
								<Typeform
									typeformId={customTypeFormId}
									popUpText={t("button.contactButton")}
									className={footerStyles.contactButton}
								/>
							)
							: (
								<Link to={ `/contact` } className={ footerStyles.contactButton }>
									{t("button.contactButton")}
								</Link>
							)
					}
					<a
						href={ discordInviteLink }
						target="_blank"
						className={footerStyles.discordButton}
						rel="nofollow"
					>
							{t("joinDiscord")}
					</a>
				</div>
			</div>
		</div>
	)
};
export default Subfooter