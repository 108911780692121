import React from "react"
import * as s from "./contact.module.less";
import * as landingPageStyle from "../landing-page.module.less"
import PaneTitle from "../../pane/pane-title/pane-title";
import Button from "../../button";
const Contact = ({
  heading,
  description,
  btn,
  img
}) => {
  return (
    <div
      className={s.contact }
      style={ {
        backgroundImage: `url(${img})`
      } }
    >
      <div className={ landingPageStyle.container }>
        <div className={ s.title }>
          <PaneTitle
              title={ heading }
              description={ description }
              isCentered
              isWhite
          />
        </div>
        <Button
            label={btn.title.toUpperCase()}
            href={btn.href}
            buttonType={ "tertiary"}
            size={"extraSmall"}
            className={ s.btn }
        />
      </div>
    </div>
  )
}
export default Contact
