import React from "react"
import * as s from "./certificates.module.less"
import * as landingPageStyle from "../landing-page.module.less"
import cn from "classnames";
import Img from "gatsby-image";
import * as Style from "../../customer-overview/customerOverview.module.less";
import PaneTitle from "../../pane/pane-title/pane-title";
const Certificates = ({
	heading,
	description,
	certificates
}) => {
	return (
		<div className={ s.certificatesPane }>
			<div className={ landingPageStyle.container }>
				<div className={ s.leftBox }>
				</div>
				<div className={ s.rightBox }>
				</div>
				<div className={ s.box}>
					<div className={ s.certificateBadges }>
						{ certificates.map (({ node }) => (
							<div className={ s.image }>
								<Img
									fadeIn={ false }
									loading={"eager"}
									imgStyle={{
										objectFit: "contain"
									}}
									alt={ node.frontmatter.name }
									fluid={ node.frontmatter.imagePath.childImageSharp.fluid }
								/>
							</div>
						))}
					</div>
					<div className={ s.textBox }>
						<PaneTitle
							title={ heading }
							description={ description }
							isWhite
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Certificates
