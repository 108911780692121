// extracted by mini-css-extract-plugin
export var gatsbyRespImageLink = "customerOverview-module--gatsby-resp-image-link--2FjwF";
export var box = "customerOverview-module--box--47z0m";
export var flexbox = "customerOverview-module--flexbox---V4Wa";
export var input = "customerOverview-module--input--AoswZ";
export var loader = "customerOverview-module--loader--yTdWH";
export var load8 = "customerOverview-module--load8--l4FWb";
export var gatsbyRespImageBackgroundImage = "customerOverview-module--gatsby-resp-image-background-image--HdMXM";
export var customer = "customerOverview-module--customer--LrZfV";
export var image = "customerOverview-module--image--Gk86-";
export var viewwidth = "customerOverview-module--viewwidth--JU9BW";
export var overwriteWidth = "customerOverview-module--overwriteWidth--dtq77";
export var customers = "customerOverview-module--customers--oCHkS";
export var employeesLink = "customerOverview-module--employeesLink--NHOJ9";