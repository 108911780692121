// extracted by mini-css-extract-plugin
export var show = "comments-module--show--pj2et";
export var root = "comments-module--root--9gtni";
export var list = "comments-module--list--OezA4";
export var form = "comments-module--form--9WO5x";
export var comment = "comments-module--comment--0d0I9";
export var wrapper = "comments-module--wrapper--jglXv";
export var avatar = "comments-module--avatar--6Fjfi";
export var name = "comments-module--name--6uOCA";
export var date = "comments-module--date--+agUC";
export var body = "comments-module--body--E-7l9";
export var reply = "comments-module--reply--etglN";