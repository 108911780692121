import React from "react";
import * as CommentsStyle from "./comments.module.less";
import Comment from "../templates/comment";

export default class Comments extends React.Component {
  constructor({ props, comments, post }) {
    super(props);
    this.createTree(comments);
    this.post = post;
    this.state = { show: true, comments };

    this.addPostToComments = this.addPostToComments.bind(this);
  }

  componentWillReceiveProps({ comments }, nextContext) {
    this.createTree(comments);
    this.setState({ comments });
  }

  addPostToComments(comment) {
    const newComments = [...this.state.comments, { comment }];
    this.createTree(newComments);

    this.setState({ comments: newComments });
  }

  render() {
    return this.renderTree(this.state.comments, this.post, true);
  }

  renderTree(comments, post, root) {
    return (
      <ul className={`${CommentsStyle.list} ${root ? CommentsStyle.root : ""}`}>
        <div>
          {comments
            .filter(c => !root || !c.comment.parentId)
            .sort(function (a, b) {
              a = new Date(a.comment.date);
              b = new Date(b.comment.date);
              return a < b ? -1 : a > b ? 1 : 0;
            })
            .map(({ comment }) => (
              <li key={comment.id}>
                <span
                  style={{ display: `${comment.children ? "block" : "none"}` }}
                  className={CommentsStyle.show}
                  onClick={() => this.toggleComments(comment)}
                >
                  {comment.show ? "[-]" : "[+]"}
                </span>
                <Comment
                  onSubmitPost={this.addPostToComments}
                  comment={comment}
                  post={post}
                />
                {comment.children && comment.show
                  ? this.renderTree(comment.children, post, false)
                  : ""}
              </li>
            ))}
        </div>
      </ul>
    );
  }

  toggleComments(comment) {
    comment.show = !comment.show;
    this.setState({
      comments: this.state.comments
    });
  }

  createTree(comments) {
    comments = comments.map(c => c.comment);
    const map = {};
    for (const comment of comments) {
      map[comment.id] = comment;
      comment.show = true;
    }

    for (const comment of comments) {
      if (comment.parentId) {
        const parent = map[comment.parentId];
        if (parent) {
          parent.children = parent.children || [];
          parent.childrenMap = parent.childrenMap || {};
          if (!parent.childrenMap[comment.id]) {
            parent.children.push({ comment });
            parent.childrenMap[comment.id] = 1;
          }
        }
      }
    }
  }
}
