import React from "react"
import TestimonialBox from "./testimonial-box"
import Slider from "../../slider"
import "./slider-edit.less"
import * as s from "./testimonials.module.less"
import * as landingPageStyle from "../landing-page.module.less"
import Pane from "../../pane/pane";
import PaneTitle from "../../pane/pane-title/pane-title";
const Testimonials = ({
  heading,
  description,
  testimonials
}) => {
  const items = testimonials
	  .filter(testimonials => testimonials)
	  .sort((testimonialA, testimonialB) => testimonialA.name.localeCompare(testimonialB.name))
	  .map((testimonial, index) => (
    <div
        className={ s.box }
        key={ `${testimonial.name}` }
    >
      <TestimonialBox testimonial={ testimonial }/>
    </div>
  ))
  const responsive = {
    0: {
      items: 1
    },
    992: {
      items: 2
    },
    // 1170: {
    //   items: 3
    // }
	}
  return (
      <Pane>
        <div className={ s.testimonials }>
          <div className={ landingPageStyle.container }>
            <PaneTitle
              title={heading}
              description={ description }
              isCentered
            />
            <Slider
              items={ items }
              autoPlay
              infinite
              ssrSilentMode={false}
              // infinite
              autoPlayInterval={ "16000" }
              animationDuration={ "800" }
              responsive={ responsive }
            />
          </div>
        </div>
      </Pane>
  )
}
export default Testimonials
