import React from "react"
import * as landingStyle from "./landing.module.less"
import officeImg from "../../media/landing-page/office.jpg"
import whoWeAreImg from "../../media/landing-page/how-we-are.png"
import automationIcon from "../../media/landing-page/automation-icon.png"
import modernizationIcon from "../../media/landing-page/modernization-icon.png"
import TwitterIcon from "../../media/svg/twitter-square.svg"
import {useI18next} from "gatsby-plugin-react-i18next";
import LinkedinIcon from "../../media/svg/linkedin-square.svg"
import Landing from "./landing-video"
import Services from "./services"
import WhyChoose from "./why-choose"
import Footer from "./footer"
import About from "./about"
import redTeam from "../../media/landing-page/team_red.png"
import Help from "./help"
import Testimonials from "./testimonials"
import Contact from "./contact"
import { graphql, StaticQuery } from "gatsby";
import {splitStringBeforeTag, extractContentBetweenTags} from "../../utils/index"
import CustomersOverview from "../customer-overview/customersOverview";
import { Helmet } from "react-helmet";
import Certificates from "./certificates";
import VideoPane from "./video-pane";
import Simplymeet from "./simplymeet";
import cn from "classnames";
import * as landingPageStyle from "./landing-page.module.less";
import * as s from "./landing/landing.module.less";
import Header from "./header";
const AbstractLandingPageDe = ({
	services,
	testimonials,
	landingContent,
	certificates,
	landingImg,
	isVideoOnLanding,
	video
}) => {
	const navElements = [
		{
		  text: "Portfolio",
		  href: "/portfolio"
		},
		{
		  text: "Team",
		  href: "/employees"
		},
		{
		  text: "Blog",
		  href: "/blog"
		},
		{
			text: "Success Stories",
			href: "/success-stories"
		}
	]
	return (
		<StaticQuery
			query={graphql`
		query {
		customers: allMarkdownRemark (
			sort: { fields: [frontmatter___order], order: ASC }
			filter: {
				frontmatter: { isDraft: { ne: true } category: { eq: "customers" }}
	  		}
		) {
			totalCount
				edges {
					node {
						id
						frontmatter {
							name
							link
							imagePath {
							childImageSharp {
								fluid(maxWidth: 200) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					fields {
						slug
					}
				}
			}
		}
        successStory: allMarkdownRemark(
          filter: {
            frontmatter: {
              category: {eq: "successStory"}
              publish: { eq: true }
            }
          }) {
            edges {
              node {
                html
                fields {
                  language
                  slug 
                }
                frontmatter {
                  companyName
                  publish
                  imagePath {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
    }
            `}
            render={ data => {
                // const filteredSuccesStories = data.successStory.edges.filter(({node}) => node.fields.language === language && node.frontmatter.publish)
                // const testimonials = filteredSuccesStories.map((item) => {
                //     return  {
                //         img: item.node.frontmatter.imagePath.childImageSharp.fluid.src,
                //         name: item.node.fields.companyName,
                //         description: extractContentBetweenTags(splitStringBeforeTag(item.node.html, "h2").slice(-1)[0], "p"),
                //         href: item.node.fields.slug
                //     }
                // })
                return(
                    <div className={landingStyle.container}>
						<Helmet>
							<title>Für alle Unternehmer | Team Parallax</title>
						</Helmet>
						<header>
							<Header
								navElements={navElements}
							/>
						</header>
						{isVideoOnLanding
							? (
								<Landing
									video={video}
									videoProps={ {
										controls: true,
										muted: false
									} }
									isFullSize={ true }
									navElements={navElements}
									backgroundImageUrl={ landingImg }
									headerBtn={ {
										text: "Contact",
										onClick: () => undefined,
										href: "/de/contact"
									} }
									content={ landingContent }
								/>
							)
							: (
								<Landing
									navElements={navElements}
									backgroundImageUrl={ landingImg }
									headerBtn={ {
										text: "Contact",
										onClick: () => undefined,
										href: "/de/contact"
									} }
									content={ landingContent }
								/>
							)

						}

						<Services
							heading="WAS WIR ANBIETEN"
							description="Wir arbeiten mit Unternehmen zusammen, um denkwürdige Erlebnisse zu schaffen."
							services={services}
							btn={ {
								text: "PORTFOLIO",
								href: "/de/portfolio"
							} }
						/>
						<CustomersOverview data={data.customers.edges} />
						{ video && !isVideoOnLanding && (
							<VideoPane
								heading={"Kurz und Knapp"}
								description={"Es braucht nicht lange, um einen Eindruck zu hinterlassen. In nur 90 Sekunden zeigen wir Ihnen, warum wir die richtige Wahl für Sie sind."}
								video={ video }
								videoProps={ {
									controls: true,
									muted: false
								} }
								isFullSize={ true }
								videoErrormessage={ "Ihr Browser kann dieses Video nicht wiedergeben. Dieses Video zeigt den Big Buck Bunny Film." }
								backgroundImage={ officeImg }
							/>
						)}
						<Help
							heading="WIE WIR HELFEN"
							description="Im Allgemeinen wollen wir, dass unsere Kunden mit unseren Dienstleistungen Geld verdienen oder sparen. Wir sind spezialisiert auf Automatisierung und Modernisierung - den Umbau alter Software in Webanwendungen."
							services={ [
								{
									icon: automationIcon,
									title: "AUTOMATION",
									description: "Damit unsere Kunden nicht viel Geld für unnötiges und ineffizientes Personal bezahlen müssen"
								},
								{
									icon: modernizationIcon,
									title: "MODERNISIERUNG",
									description: "Damit ihre alte Software in neuem Glanz erstrahlt (bessere UX, schneller, weniger Wartungsaufwand, von allen Geräten aus zugänglich usw.)"
								}
							] }
							text="Wir übernehmen die volle Verantwortung für den Erfolg unserer Kunden"
						/>
						<WhyChoose
							header={"Warum"}
							teamName="TEAM PARALLAX"
							description="Wir nutzen die modernsten Technologien, um Unternehmen bei der Skalierung ihrer Fähigkeiten in den Bereichen Entwicklung, Design und digitales Marketing zu unterstützen."
							backgroundImage={ officeImg }
						/>
						<About
							heading="WER WIR SIND"
							description1="Als junges IT-Unternehmen freuen wir uns darauf, Ihre Ideen umzusetzen und/oder sie gemeinsam mit Ihnen zu entwickeln."
							description2="Wir lieben Herausforderungen!"
							description3="Ob Web- oder mobile Anwendungen, E-Commerce, sicherheitsrelevante Anwendungen, Gamification, Effizienz- und Produktivitätssteigerungen - wir finden eine Lösung, die zu Ihrem Geschäft und Ihren Zielgruppen passt."
							btn={ {
							text: "MEET OUR TEAM",
							onClick: () => undefined,
							href: "/de/employees/"
							} }
							img={ whoWeAreImg }
						/>
						{ certificates && (
							<Certificates
								heading="Wir sind zertifiziert"
								description="Unser Team verfügt über Zertifikate in den verschiedensten Bereichen um die beste Expertise für unsere Kunden zu haben."
								certificates={ certificates } />
						)}
						<Testimonials
							heading="TESTIMONIALS"
							description="Das sagen unsere Kunden über uns"
							testimonials={testimonials}
						/>
						<Simplymeet
							heading={"LET’S GET STARTED"}
							description={"Ein Projekt oder eine Partnerschaft ist geplant? Vereinbaren Sie direkt ihren Termin zu einem ersten Kennenlernen mit Team Parallax"}
						/>
						{/*<Contact*/}
						{/*    heading="Let's Get Started"*/}
						{/*    description="Ein Projekt oder Partnerschaft geplant? Jetzt für eine gratis Beratung kontaktieren!"*/}
						{/*    btn={ {*/}
						{/*    title: "Kontakt",*/}
						{/*    onClick: () => undefined,*/}
						{/*    href: "/de/contact"*/}
						{/*    } }*/}
						{/*    img={ redTeam }*/}
						{/*/>*/}

						<Footer
							socialIcons={ [
							{
								icon: <LinkedinIcon/>,
								href: "https://www.linkedin.com/company/team-parallax"
							},
							{
								icon: <TwitterIcon/>,
								href: "https://twitter.com/InsOp_de"
							}
							] }
							links={ navElements }
							address="Magirus-Deutz-Straße 12, 89077 Baden&#x2011;Württemberg - Ulm"
							phone="+49 731 14 61 38 00"
						/>

					</div>
			)}}
		>

		</StaticQuery>
	)
}
export default AbstractLandingPageDe
