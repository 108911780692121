import React from "react"
import * as Style from "./header-slider.module.less"
import ParticleWrapper from "../particle-wrapper/index";
import {Link, useTranslation} from "gatsby-plugin-react-i18next";
import Slider from "../slider/index"
import Img from "gatsby-image";
import Plate from "../../media/svg/plate.svg";
function renderProduct(node) {
	return (
		<div
			key={ node.id }
			className={ Style.headerItem }
		>
			<Link
				to={ node.fields.slug }
				className={ Style.link }
			>
				<div className={ Style.itemContainer }>
					<div className={ Style.description }>
						<h3>
							{ node.frontmatter.title }
						</h3>
						<i>
							{ node.frontmatter.subtitle }
						</i>
					</div>
					<div className={ Style.imageContainer }>
						<Plate
							className={ Style.plate }
						/>
						{ node.frontmatter.smallImagePath ? (
							<Img
								className={ Style.image }
								durationFadeIn={ 0 }
								loading={ "eager" }
								fadeIn={ false }
								alt={ node.frontmatter.title }
								fluid={ node.frontmatter.smallImagePath.childImageSharp.fluid }
							/>
						) : (
							""
						)}
					</div>

				</div>
			</Link>
		</div>
	)
}
function getItems(data) {
	return data.map(({ node }) => (
		renderProduct(node)
	))
}
export const HeaderSlider = ({
	data
}) => {
	const {
		t
	} = useTranslation()
	const red = "#d83826"
	const white = "#ffffff"
	const items = getItems(data)
	return (
		<div className={ Style.headerContainer }>
			<div className={ Style.content}>
				<div className={ Style.title }>
					<h1>{t("index.h1")}</h1>
					<strong>
						{ t("index.slogan") }
					</strong>
				</div>
				<div className={ Style.sliderContainer }>
					<Slider
						items={ items }
						autoPlay={ true }
						infinite={ true }
						autoPlayInterval={ "8000" }
						animationDuration={ "800" }
						className={ Style.slider }
					/>
				</div>
			</div>
			<div className={ Style.particlesContainer }>
				<ParticleWrapper
					backgroundColor={ red }
					particleColor={ white }
					linkColor={ white }
					id={ "headerParticles" }
				/>
			</div>
		</div>
	)
}
export default HeaderSlider
