import React from "react";
import * as CommentsStyle from "../components/comments.module.less";
import PostComment from "../components/post-comment";
import ReactMarkdown from "react-markdown";

export default class Comment extends React.Component {
  constructor({ props, comment, post }) {
    super(props);
    this.date = formatDate(new Date(comment.date));
    this.props = props;
    this.post = post;
    this.comment = comment;
    this.state = { reply: false };

    this.addPostToComments = this.addPostToComments.bind(this);
  }

  addPostToComments(comment) {
    this.props.onSubmitPost(comment);
    this.setState({ reply: false });
  }

  render() {
    return (
      <div>
        <div className={CommentsStyle.comment}>
          <img
            className={CommentsStyle.avatar}
            alt={this.comment.name}
            src={`https://www.gravatar.com/avatar/${
              this.comment.email
            }?s=100&d=identicon&r=pg`}
          />
          <div className={CommentsStyle.wrapper}>
            <h5 className={CommentsStyle.name}>{this.comment.name}</h5>
            <p className={CommentsStyle.date}>{this.date}</p>
            <div className={CommentsStyle.body}>
              <div>
                <ReactMarkdown children={this.comment.comment} />
              </div>
              <span
                className={CommentsStyle.reply}
                onClick={() => {
                  this.setState({ reply: !this.state.reply });
                }}
              >
                reply
              </span>
            </div>
          </div>
        </div>
        {this.state.reply ? (
          <div className={CommentsStyle.form}>
            <PostComment
              onSubmitPost={this.addPostToComments}
              parentId={this.comment.id}
              post={this.post}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function formatDate(date) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return day + " " + monthNames[monthIndex] + ", " + year;
}
