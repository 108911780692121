import React from "react"
import * as s from "./why-choose.module.less"
import * as landingPageStyle from "../landing-page.module.less"
import network from "../../../media/landing-page/network icon.png"
import PaneTitle from "../../pane/pane-title/pane-title";
const WhyChoose = ({
  description,
  teamName,
	header,
  backgroundImage
}) => {
  return (
    <div className={ s.whyChoose }>
      <div className={ landingPageStyle.container }>
        <div
          className={ s.leftBox }
          style={ {
            backgroundImage: `url(${backgroundImage})`
          } }
        >
          <div className={ s.overlay }/>
        </div>
        <div className={ s.rightBox }>
          <img alt="network icon" className={ s.networkImg } src={ network }/>
        </div>
        <div className={ s.box }>
          <div className={ s.textBox }>
            { description }
          </div>
          <div className={ s.heading }>
            <PaneTitle
                title={header ?? "Why Choose"}
                description={teamName}
                isWhite
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default WhyChoose
