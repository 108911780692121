// extracted by mini-css-extract-plugin
export var gatsbyRespImageLink = "post-comment-module--gatsby-resp-image-link--698BU";
export var box = "post-comment-module--box--6q7BO";
export var flexbox = "post-comment-module--flexbox--tJj+C";
export var input = "post-comment-module--input--OGeqG";
export var loader = "post-comment-module--loader--EG3lL";
export var load8 = "post-comment-module--load8--yGSiu";
export var gatsbyRespImageBackgroundImage = "post-comment-module--gatsby-resp-image-background-image--N7PoW";
export var flex = "post-comment-module--flex--UDbU7";
export var email = "post-comment-module--email--M3O52";
export var name = "post-comment-module--name--jFedK";
export var submit = "post-comment-module--submit--Ll2cr";
export var error = "post-comment-module--error--R81UT";
export var comment = "post-comment-module--comment--6HkwD";