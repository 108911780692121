import React from "react";
import { graphql } from "gatsby";
import { Link } from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import * as Style from "./blog.module.less";
import Favicon from "../media/favicon/x.ico";
const BlogDeprecatedPage =  ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Blog | Team Parallax</title>
        <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
      </Helmet>
      <div className={Style.blogContainer}>
        <h4>{data.articles.totalCount} Posts</h4>
        {data.articles.edges.map(({ node }) => (
          <div key={node.id}>
            <Link to={node.fields.slug}>
              <h3 className={Style.headline}>{node.frontmatter.title}</h3>
              <span className={Style.date}>{node.frontmatter.date} - by {node.frontmatter.author}</span>
              <p>{node.excerpt}</p>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  );
};
export default BlogDeprecatedPage
export const query = graphql`
  query {
    articles: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { isDraft: { ne: true } category: { eq: "blog" } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            author
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
