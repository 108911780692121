import React from "react"
import { graphql } from "gatsby";
import AbstractLandingPage from "../../components/landing-page"
import exampleVideo from "../../media/videos/big_buck_bunny.mp4"
import {extractContentBetweenTags, splitStringBeforeTag} from "../../utils";
import {useI18next} from "gatsby-plugin-react-i18next";
const Landingpage = ({data}) => {
	const {
		products,
		testimonials
	} = data
	const {
		language
	} = useI18next()
	const landingContent = {
		heading: "Web and Mobile App Development",
		description: "END-TO-END CUSTOM SOFTWARE SOLUTIONS",
	}
	const productElements = products.edges
	const services = productElements.map(item => {
		return {
			img: item.node.frontmatter.smallImagePath.childImageSharp.fluid.src,
			title: item.node.frontmatter.title,
			description: item.node.frontmatter.summary,
			btn: {
				text: "read more",
				href: item.node.fields.slug
			}
	    }
	})
	const testimonialPage = "development"
	const testimonialData = testimonials.edges.filter(({node}) => node.fields.language === language && node.frontmatter.pages.includes(testimonialPage)).map((item) => {
		return {
			img: item.node.frontmatter.imagePath.childImageSharp.fluid,
			name: item.node.frontmatter.name,
			company: item.node.frontmatter.company,
			description: extractContentBetweenTags(splitStringBeforeTag(item.node.html, "h2").slice(-1)[0], "p"),
			href: item.node.frontmatter.href
		}
	})
	return (
		<AbstractLandingPage
			services={ services  }
			landingContent={ landingContent}
			certificates={ data.certificates.edges }
			testimonials={ testimonialData }
			video={ exampleVideo }
		/>
	)
}
export default Landingpage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
            node {
                ns
                data
                language
            }
        }
    }
    products: allMarkdownRemark(
        limit: 3
        sort: { fields: [frontmatter___title], order: ASC }
        filter: {
            fields: {
                language: { eq: $language }
            }
            frontmatter: {
              category: { eq: "portfolio" }
              isDraft: { ne: true },
              isDevelopment: { eq: true }}
        }
    ) {
      edges {
        node {
          id
          frontmatter {
            summary
            title
            smallImagePath {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  	certificates: allMarkdownRemark (
		sort: { fields: [frontmatter___order], order: ASC }
		filter: {
			frontmatter: {
        isDraft: { ne: true }
        category: { eq: "certificates" }
      }
		}
    ) {
        totalCount
        edges {
            node {
                id
                frontmatter {
                    name
                    imagePath {
                        childImageSharp {
                            fluid(maxWidth: 200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                fields {
                    slug
                }
            }
        }
    }
	testimonials: allMarkdownRemark(
		filter: {
			fields: {
					language: { eq: $language }
				}
			frontmatter: {
				category: {eq: "testimonial"}
				publish: {eq: true }
			}
		}
	) {
		edges {
			node {
				html
				fields {
					language
					slug
				}
				frontmatter {
					name
					company
					href
					pages
					imagePath {
						childImageSharp {
							fluid {
							...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
  }
`;
