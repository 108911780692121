import React from "react"
import cn from "classnames"
import * as s from "./footer.module.less";
import { Link } from 'gatsby-plugin-react-i18next';
import logo from "../../../media/logo/tpx_s_orange.png"
import * as landingPageStyle from "../landing-page.module.less"
const Footer = ({
  socialIcons,
  links,
  address,
  phone,
  mobile
}) => {
  return (
    <footer className={ s.footer }>
      <div className={ cn(landingPageStyle.container, s.row) }>
        <div className={ cn(s.col, s.leftAligned) }>
          <p className={ s.followText }>FOLLOW US</p>
          <div className={ s.socialIcons }>
            {socialIcons.map((element, i) => (
              <a
                  className={ s.icon }
                  key={ `social-media-${i}-${element}` }
                  href={ element.href }
              >
                  { element.icon }
              </a>
            ))}
          </div>
          <div className={ s.links }>
            {[...links,
				{
					text: "Imprint",
					href: "/imprint"
				}
			].map((link) => (
              <Link
                to={link.href}
                activeClassName={ s.activeLink }
                className={ s.link }
              >
                {link.text}
              </Link>
            ))}
          </div>
        </div>
        <div className={ s.col }></div>
        <div className={ cn(s.col, s.rightAligned) }>
          <Link to={`/`} className={ s.logo }>
            <img
                alt={"Logo of TeamParallax"}
                src={logo}
            />
          </Link>
          <p className={ s.address }>Address: { address }</p>
          <p>Phone: { phone }</p>
			{mobile && <p>Mobile: { mobile }</p>}
        </div>
      </div>
    </footer>
  )
}
export default Footer
