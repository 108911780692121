// extracted by mini-css-extract-plugin
export var service = "service-module--service--+-dvd";
export var serviceImg = "service-module--serviceImg--IdMPf";
export var textBox = "service-module--textBox--CPLwj";
export var titleBox = "service-module--titleBox--SBiCb";
export var title = "service-module--title--BiQZA";
export var description = "service-module--description--N9oTl";
export var btnBox = "service-module--btnBox--lsczS";
export var btn = "service-module--btn--KCtIk";
export var rightArrow = "service-module--rightArrow--k-kAY";
export var arrow = "service-module--arrow--AwL5F";