import React from "react"
import * as s from "./how-to-help-services.module.less"
const HowToHelpService = ({
  service: {
    title,
    description,
    icon
  }
}) => {
  return (
    <div className={ s.mainBox }>
      <img className={ s.icon } src={ icon } alt={ title }/>
      <div className={ s.textBox }>
        <h1 className={ s.heading }>{ title }</h1>
        <p className={ s.description }>{ description }</p>
      </div>
    </div>
  )
}
export default HowToHelpService