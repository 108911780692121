import React from "react"
import * as s from "./video-pane.module.less"
import * as landingPageStyle from "../landing-page.module.less";
import cn from "classnames";
import Video from "./video";
import PaneTitle from "../../pane/pane-title/pane-title";
const VideoPane = ({
	heading,
	description,
	video,
	videoProps,
	videoErrormessage,
	backgroundImage,
	isFullSize
}) => {
	return (
	<div className={ s.videoPane }>
		<div className={ landingPageStyle.container }>
			<div
				className={ s.leftBox }
				style={ {
					backgroundImage: `url(${backgroundImage})`
				} }
			>
				<div className={ s.overlay }/>
			</div>
			<div className={ s.rightBox }>
			</div>
			<div className={ s.box }>
				<Video
					classnames={ cn(s.video, {
						[s.fullSize ]: isFullSize
					}) }
					video={ video }
					videoProps={ videoProps }
					videoErrormessage={ videoErrormessage }
					isLarge={ false }
				/>
				<div className={ s.heading }>
					<PaneTitle
						title={heading}
						description={description}
						isWhite
					/>
				</div>
			</div>
		</div>
	</div>
	)
}
export default VideoPane
