// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--Nj7sG";
export var row = "footer-module--row--u2-ns";
export var col = "footer-module--col--nDVqO";
export var leftAligned = "footer-module--left-aligned--FX8fe";
export var rightAligned = "footer-module--right-aligned--Sgg6j";
export var logo = "footer-module--logo--UdwVg";
export var followText = "footer-module--followText--4OoXR";
export var socialIcons = "footer-module--socialIcons--2G5gv";
export var icon = "footer-module--icon--cwnoz";
export var links = "footer-module--links--bMWWI";
export var activeLink = "footer-module--activeLink--4x80b";
export var link = "footer-module--link--PqOTi";
export var address = "footer-module--address--gqP7B";