// extracted by mini-css-extract-plugin
export var portfolio = "portfolio-module--portfolio--dbtfn";
export var post = "portfolio-module--post--qeEEm";
export var description = "portfolio-module--description--0FDF-";
export var titleHolder = "portfolio-module--titleHolder--5SHh4";
export var preview = "portfolio-module--preview--vVxJs";
export var orange = "portfolio-module--orange--r-8En";
export var purpleToOrange = "portfolio-module--purple-to-orange--jgKCx";
export var purple = "portfolio-module--purple---kINW";
export var petrol = "portfolio-module--petrol--9nxVp";
export var petrolToOrange = "portfolio-module--petrol-to-orange--XPDI9";